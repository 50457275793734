import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ReactPlayer from "react-player/lazy"
import styled from "styled-components"
import Banner from "../components/banner"

const AboutStyles = styled.section`
  .top-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5%;

    .caption {
      flex: 0 0 30%;
      padding: 5%;
      background-color: var(--dgrey);
      color: #fff;

      .sub-title {
        color: var(--blue);
      }

      h2 {
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 1.2;
        font-size: 1.8vw;
        margin-top: 3rem;
        margin-bottom: 0;
      }
    }
  }

  .about {
    margin: 9rem auto;

    h2 {
      font-weight: 600;
      font-size: 2vw;
      line-height: 1.2;
    }

    p {
      font-weight: 500;
      font-size: 2.2rem;
      line-height: 1.8;
      margin: 4rem 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .row {
      align-items: center;

      .col {
        flex: 1 1 67%;

        &:last-child {
          flex: 1 1 33%;
        }
      }

      .vision {
        h3 {
          display: block;
          font-size: 1.5vw;
          position: relative;
          text-align: right;
          padding: 3rem 0 3rem 50%;
          color: var(--lgrey);
          font-weight: 600;
          line-height: 1.2;

          strong {
            font-weight: 700;
            color: var(--blue);
          }

          &:before,
          &:after {
            position: absolute;
            content: "";
            display: block;
            width: 54px;
            height: 3px;
            background-color: var(--blue);
            top: 0;
            right: 0;
          }

          &:after {
            top: auto;
            bottom: 0;
          }
        }
      }
    }
  }

  .services {
    color: #fff;
    position: relative;
    overflow: hidden;

    .player-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      z-index: -1;

      .react-player {
        video {
          position: absolute;
          min-width: 100%;
          min-height: 100%;
          width: auto !important;
          height: auto !important;
          top: 50%;
          left: 50%;
          object-fit: contain;
          transform: translate(-50%, -50%);
        }
      }
    }

    .container {
      .row {
        margin: 0 -6%;

        .col {
          padding: 0 6%;
          margin-top: 12rem;

          h2 {
            margin-bottom: 4rem;
            font-size: 1.8rem;
            color: var(--blue);
          }

          p {
            font-size: 2.8rem;
            line-height: 1.6;
            font-weight: 600;
            margin-bottom: 4rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .btn {
            margin-top: 4rem;
          }

          .gatsby-image-wrapper {
            padding: 0;
            width: 40% !important;
            margin: 6rem auto 0;
            display: block;

            img {
              display: block;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }

  .team {
    padding: 0 5%;

    .team-title {
      margin: 10rem;

      h2 {
        margin: 4.5rem 0 0;
        font-size: 2.2vw;
        line-height: 1.4;
        font-weight: 600;
        width: 50%;
      }
    }

    .team-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -3rem;

      .team-item {
        flex: 0 0 calc(25% - 6rem);
        margin: 0 3rem 9rem;

        .team-image {
          position: relative;

          img {
            display: block;
            transition: filter 0.3s ease !important;
          }

          .view {
            position: absolute;
            bottom: 30px;
            left: 30px;
            width: calc(100% - 60px);
            color: #fff;
            text-align: right;
            padding-bottom: 1rem;
            font-size: 1.8rem;
            font-weight: 600;
            border-bottom: 5px solid #fff;
          }
        }

        h3 {
          margin-top: 4rem;
          font-size: 2.3rem;
          line-height: 1.7;
          font-weight: 600;
          color: var(--body);
        }

        p {
          margin-bottom: 0;
          font-size: 1.8rem;
          line-height: 1.7;
          font-weight: 600;
          color: var(--body);
        }

        &:hover {
          .team-image {
            img {
              filter: brightness(77%) opacity(100%);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .top-banner {
      .caption {
        flex: 0 0 90%;

        h2 {
          font-size: 2.5rem;
        }
      }
    }

    .about {
      .row {
        .col {
          flex: 1 1 100%;

          h2 {
            font-size: 2.5rem;
          }
        }

        .vision {
          h3 {
            text-align: center;
            padding-left: 0;
            margin-top: 6rem;
            font-size: 2.5rem;

            &:before,
            &:after {
              right: calc(50% - 27px);
            }
          }
        }
      }
    }

    .services {
      .container {
        .row {
          .col {
            flex: 1 1 100%;
          }
        }
      }
    }

    .team {
      .team-title {
        margin: 10rem 0;

        h2 {
          width: 100%;
          font-size: 2.5rem;
        }
      }

      .team-list {
        .team-item {
          flex: 1 1 calc(50% - 6rem);

          .team-image {
            img {
              width: 100%;
              max-width: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .team {
      .team-list {
        .team-item {
          flex: 1 1 calc(100% - 6rem);
        }
      }
    }
  }
`

const About = ({ data }) => {
  const acf = { ...data.acfAbout }
  const team = useStaticQuery(graphql`
    query TeamQuery {
      allWpTeam(sort: { order: ASC, fields: date }) {
        nodes {
          acfTeam {
            button {
              submitProjectGfFormId
              title
            }
            position
            services
          }
          content
          featuredImage {
            node {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
          title
          slug
        }
      }
    }
  `)

  return (
    <AboutStyles>
      <div className="top-banner">
        {acf.topBanner.background &&
        acf.topBanner.background.localFile &&
        acf.topBanner.background.localFile.childImageSharp &&
        acf.topBanner.background.localFile.childImageSharp.fluid ? (
          <div className="background-image">
            <Img
              fluid={acf.topBanner.background.localFile.childImageSharp.fluid}
              alt={acf.topBanner.background.altText}
            />
          </div>
        ) : null}
        <div className="caption">
          {acf.topBanner.subTitle ? (
            <div className="sub-title">{acf.topBanner.subTitle}</div>
          ) : null}

          {acf.topBanner.title ? <h2>{acf.topBanner.title}</h2> : null}
        </div>
      </div>
      <div className="about container container-full">
        <div className="row">
          <div className="col">
            {acf.content.title ? <h2>{acf.content.title}</h2> : null}

            {acf.content.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.content.content }}
              ></div>
            ) : null}
          </div>
          <div className="col vision">
            {acf.content.vision ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.content.vision }}
              ></div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="services">
        {acf.services.background &&
        acf.services.background.localFile &&
        acf.services.background.localFile.childImageSharp &&
        acf.services.background.localFile.childImageSharp.fluid ? (
          <div className="background-image">
            <Img
              fluid={acf.services.background.localFile.childImageSharp.fluid}
              alt={acf.services.background.altText}
            />
          </div>
        ) : null}

        {acf.services.backgroundVideo && acf.services.backgroundVideo.guid ? (
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={acf.services.backgroundVideo.guid}
              width="100%"
              height="100%"
              muted
              playing
              loop
            />
          </div>
        ) : null}

        <div className="container container-full">
          {acf.services.columns.length ? (
            <div className="row">
              {acf.services.columns.map(column => {
                return (
                  <div className="col">
                    {column.title ? (
                      <h2 className="sub-title">{column.title}</h2>
                    ) : null}

                    {column.content ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: column.content }}
                      ></div>
                    ) : null}

                    {column.button &&
                    column.button.title &&
                    column.button.link ? (
                      <Link to={column.button.link} className="btn btn-light">
                        {column.button.title}
                      </Link>
                    ) : null}
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>
      </div>

      <div className="team container container-full" id="team">
        <div className="team-title">
          {acf.team.subTitle ? (
            <div className="sub-title">{acf.team.subTitle}</div>
          ) : null}

          {acf.team.title ? <h2>{acf.team.title}</h2> : null}
        </div>

        {team && team.allWpTeam && team.allWpTeam.nodes.length ? (
          <div className="team-list">
            {team.allWpTeam.nodes.map(team => {
              return (
                <div className="team-item">
                  <Link to={`/${team.slug}`}>
                    <div className="team-image">
                      {team.featuredImage &&
                      team.featuredImage.node &&
                      team.featuredImage.node.localFile &&
                      team.featuredImage.node.localFile.childImageSharp &&
                      team.featuredImage.node.localFile.childImageSharp
                        .fluid ? (
                        <Img
                          fluid={
                            team.featuredImage.node.localFile.childImageSharp
                              .fluid
                          }
                          alt={team.featuredImage.node.altText}
                        />
                      ) : null}
                      <div className="view">View bio</div>
                    </div>

                    {team.title ? <h3>{team.title}</h3> : null}

                    {team.acfTeam.position ? (
                      <p>{team.acfTeam.position}</p>
                    ) : null}
                  </Link>
                </div>
              )
            })}
          </div>
        ) : null}
      </div>

      <Banner data={acf.banner} />
    </AboutStyles>
  )
}

export default About
