import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import PageHeader from "../components/page-header"
import Banner from "../components/banner"

const SolutionsStyles = styled.section`
  .page-header h1 {
    color: #fff;
  }

  .banner {
    text-align: center;
    background-color: var(--grey);
    padding: 5% 0;

    h2 {
      margin-top: 0;
    }
  }

  .content {
    margin: 12rem auto;
    text-align: center;

    h2 {
      font-size: 2vw;
      line-height: 1.7;
      font-weight: 600;
      margin: 0;
    }
  }

  .services {
    padding: 0 3rem;
    margin: 6rem auto 12rem;

    .row {
      .col {
        padding: 0 3rem;

        img {
          transition: filter 0.3s ease !important;
        }

        .service-content {
          padding: 0 8%;
        }

        a {
          img {
            &:hover {
              filter: brightness(77%) opacity(100%);
            }
          }
        }

        h3 {
          font-weight: 700;
          font-size: 1.5vw;
          line-height: 1.7;
          margin-bottom: 1.25rem;
        }

        .empower {
          margin: 1.25rem 0 4rem;
          font-size: 1vw;
          line-height: 1.7;
          font-weight: 500;
        }

        p,
        ul {
          font-size: 2rem;
          line-height: 1.5;
          font-weight: 500;
          margin: 0 0 3rem;
        }

        ul {
          padding-left: 2rem;
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .content h2 {
      font-size: 2.5rem;
    }

    .services {
      padding: 0 10%;

      .row {
        margin-left: -3rem;
        margin-right: -3rem;

        .col {
          margin-bottom: 6rem;

          h3 {
            font-size: 2.5rem;
          }

          .empower {
            font-size: 2rem;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }
`

const Solutions = ({ data }) => {
  const acf = { ...data.acfSolutions }

  return (
    <SolutionsStyles>
      <PageHeader data={acf.header} />

      <div className="content container">
        {acf.solutions.title ? <h2>{acf.solutions.title}</h2> : null}
      </div>

      {acf.solutions.solutions && acf.solutions.solutions.length ? (
        <div className="services container container-full">
          <div className="row">
            {acf.solutions.solutions.map(col => {
              return (
                <div className="col">
                  {col.button && col.button.link ? (
                    <Link to={col.button.link}>
                      {col.image &&
                      col.image.localFile &&
                      col.image.localFile.childImageSharp &&
                      col.image.localFile.childImageSharp.fluid ? (
                        <Img
                          fluid={col.image.localFile.childImageSharp.fluid}
                          alt={col.image.altText}
                        />
                      ) : null}
                    </Link>
                  ) : (
                    <React.Fragment>
                      {col.image &&
                      col.image.localFile &&
                      col.image.localFile.childImageSharp &&
                      col.image.localFile.childImageSharp.fluid ? (
                        <Img
                          fluid={col.image.localFile.childImageSharp.fluid}
                          alt={col.image.altText}
                        />
                      ) : null}
                    </React.Fragment>
                  )}

                  <div class="service-content">
                    {col.title ? <h3>{col.title}</h3> : null}

                    {col.subTitle ? (
                      <div className="empower">{col.subTitle}</div>
                    ) : null}

                    {col.content ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: col.content }}
                      ></div>
                    ) : null}

                    {col.button && col.button.title && col.button.link ? (
                      <Link to={col.button.link} className="btn">
                        {col.button.title}
                      </Link>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}

      <Banner data={acf.banner} />
    </SolutionsStyles>
  )
}

export default Solutions
