import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { BsArrowRight } from "react-icons/bs"

const FeaturedStyles = styled.div`
  .featured {
    margin: 9rem 0;

    .row {
      margin: 0 -6rem;

      .col {
        margin: 0 6rem;

        img {
          display: block;
        }

        .gatsby-image-wrapper {
          /* padding-top: 66.6666%; */
        }

        .date {
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 1.9rem;
          letter-spacing: 4px;
          color: var(--blue);
          margin-bottom: 1.5rem;
        }

        a {
          color: var(--body);

          &:hover {
            color: var(--blue);
          }
        }

        h3 {
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 4.1rem;
        }

        p {
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 2.8rem;
        }

        .more {
          color: var(--blue);
          font-size: 1.6rem;
          line-height: 2.1rem;
          font-weight: 600;

          svg {
            vertical-align: middle;
          }

          &:hover {
            color: var(--body);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .featured {
      .gatsby-image-wrapper {
        margin-bottom: 3rem;
      }
    }
  }
`

const Featured = () => {
  const featured = useStaticQuery(graphql`
    query FeaturedQuery {
      allWpPost(
        sort: { fields: date, order: DESC }
        filter: { tags: { nodes: { elemMatch: { name: { eq: "featured" } } } } }
        limit: 1
      ) {
        edges {
          node {
            title
            slug
            excerpt
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                sourceUrl
              }
            }
            date(formatString: "LL")
          }
        }
      }
    }
  `)

  return (
    <FeaturedStyles>
      {featured &&
      featured.allWpPost &&
      featured.allWpPost.edges.length &&
      featured.allWpPost.edges[0].node ? (
        <div className="featured container container-full">
          <div className="row">
            <div className="col">
              <Link to={`/${featured.allWpPost.edges[0].node.slug}`}>
                {featured.allWpPost.edges[0].node.featuredImage &&
                featured.allWpPost.edges[0].node.featuredImage.node &&
                featured.allWpPost.edges[0].node.featuredImage.node.localFile &&
                featured.allWpPost.edges[0].node.featuredImage.node.localFile
                  .childImageSharp &&
                featured.allWpPost.edges[0].node.featuredImage.node.localFile
                  .childImageSharp.fluid ? (
                  <Img
                    fluid={
                      featured.allWpPost.edges[0].node.featuredImage.node
                        .localFile.childImageSharp.fluid
                    }
                    alt={
                      featured.allWpPost.edges[0].node.featuredImage.node
                        .altText
                    }
                  />
                ) : null}
              </Link>
            </div>
            <div className="col">
              <div className="date">
                {featured.allWpPost.edges[0].node.date}
              </div>

              <Link to={`/${featured.allWpPost.edges[0].node.slug}`}>
                <h3>{featured.allWpPost.edges[0].node.title}</h3>
              </Link>

              <div
                dangerouslySetInnerHTML={{
                  __html: featured.allWpPost.edges[0].node.excerpt,
                }}
              ></div>

              <Link
                to={`/${featured.allWpPost.edges[0].node.slug}`}
                className="more"
              >
                Read more <BsArrowRight />
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </FeaturedStyles>
  )
}

export default Featured
