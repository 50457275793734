import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Form from "./form"

const ContactStyles = styled.section`
  .contact-header {
    background-size: cover;
    background-position: center;
    padding-top: 12rem;
    padding-bottom: 12rem;
    color: #fff;
    position: relative;

    .row {
      align-items: center;

      .col {
        flex: 0 0 40%;

        h1 {
          font-weight: 500;
          font-size: 1.8vw;
          line-height: 1.7;

          strong {
            display: block;
          }
        }

        &:last-child {
          background-color: var(--dgrey);
          padding: 7.5rem;
          flex: 0 0 50%;
          margin-left: 10%;
          box-sizing: border-box;

          .sub-title {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
          }

          a {
            display: block;
            color: #fff;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.5rem;
            margin-bottom: 2.5rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          div {
            p {
              line-height: 1.8;
              font-size: 1.8rem;
            }

            &:last-child {
              p {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .contact-form {
    margin: 12rem 0;

    h2 {
      text-align: center;
      font-weight: 600;
      font-size: 1.8vw;
      line-height: 1.2;
      margin-top: 0;
      margin-bottom: 6rem;
      padding: 0 3rem;
    }

    .gform {
      padding: 0 15%;
    }
  }

  @media screen and (max-width: 1080px) {
    .contact-header {
      .row {
        .col {
          flex: 1 1 100%;

          h1 {
            font-size: 2.5rem;
            padding: 0 7.5rem 3rem;
          }

          &:last-child {
            flex: 1 1 100%;
            margin: 0;
          }
        }
      }
    }

    .contact-form h2 {
      font-size: 2.5rem;
    }
  }
`

const Contact = ({ data }) => {
  const acf = { ...data.acfContact }

  return (
    <ContactStyles>
      <div className="contact-header container container-full">
        {acf.header.background &&
        acf.header.background.localFile &&
        acf.header.background.localFile.childImageSharp &&
        acf.header.background.localFile.childImageSharp.fluid ? (
          <div className="background-image background-image-gradient">
            <Img
              fluid={acf.header.background.localFile.childImageSharp.fluid}
              alt={acf.header.background.altText}
            />
          </div>
        ) : null}

        <div className="row">
          <div className="col">
            {acf.header.title ? (
              <h1>
                {acf.header.title}

                {acf.header.subTitle ? (
                  <strong>{acf.header.subTitle}</strong>
                ) : null}
              </h1>
            ) : null}
          </div>

          {acf.header.info && acf.header.info.length ? (
            <div className="col">
              {acf.header.info.map(col => {
                return (
                  <React.Fragment>
                    {col.title ? (
                      <div className="sub-title">{col.title}</div>
                    ) : null}

                    {col.content ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: col.content }}
                      ></div>
                    ) : null}
                  </React.Fragment>
                )
              })}
            </div>
          ) : null}
        </div>
      </div>
      <div className="contact-form container container-full">
        {acf.form.title ? <h2>{acf.form.title}</h2> : null}

        <Form data={acf.form.submitProjectGfFormId} />
      </div>
    </ContactStyles>
  )
}

export default Contact
