import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const PageHeaderStyles = styled.section`
  .page-header {
    padding: 20rem 0;
    background-size: cover;
    background-position: center;
    color: #fff;
    position: relative;

    .container-full {
      padding: 0 10%;
    }

    h1 {
      margin: 0;
      color: #fff;
    }

    h2 {
      font-size: 2.2vw;
      line-height: 1.2;
      margin: 4rem 0;
      font-weight: 700;
      width: 55%;
    }

    p {
      font-size: 2.2rem;
      line-height: 1.8;
      font-weight: 500;
      margin-bottom: 0;
      width: 55%;
    }
  }

  @media screen and (max-width: 1080px) {
    .page-header {
      p,
      h2 {
        width: 100%;
      }

      h2 {
        font-size: 3rem;
      }
    }
  }
`

const PageHeader = ({ data }) => {
  return (
    <PageHeaderStyles>
      {data ? (
        <div className="page-header">
          {data.background &&
          data.background.localFile &&
          data.background.localFile.childImageSharp &&
          data.background.localFile.childImageSharp.fluid ? (
            <div className="background-image">
              <Img
                fluid={data.background.localFile.childImageSharp.fluid}
                alt={data.background.altText}
              />
            </div>
          ) : null}

          <div class="container container-full">
            {data.title ? <h1 className="sub-title">{data.title}</h1> : null}

            {data.subTitle ? <h2>{data.subTitle}</h2> : null}

            {data.content ? (
              <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
            ) : null}
          </div>
        </div>
      ) : null}
    </PageHeaderStyles>
  )
}

export default PageHeader
