import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

SwiperCore.use([Navigation])

const PartnersSliderStyles = styled.section`
  .logos-wrap {
    padding: 0 6rem 16rem;

    h2 {
      text-align: center;
      margin-bottom: 4rem;
      font-weight: 700;
      font-size: 1.5vw;
      line-height: 1.2;
    }

    .logos {
      .swiper-container {
        /* width: auto; */

        .swiper-button-disabled {
          display: none;
        }

        .swiper-wrapper {
          align-items: center;
        }
      }

      a {
        img {
          display: block;
          filter: grayscale(100%);
          transition: filter 0.3s ease;

          &:hover {
            filter: none;
          }
        }

        .gatsby-image-wrapper {
          width: 50%;
          margin: 0 auto;
        }

        /* .gatsby-image-wrapper {
          padding-top: 0;

          img {
            display: none !important;
            height: auto !important;
          }

          picture {
            img {
              display: block !important;
              position: relative !important;
            }
          }
        } */
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .logos-wrap {
      h2 {
        font-size: 3rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .logos-wrap {
      .logos {
        flex-wrap: wrap;

        a {
          flex: 1 1 100%;
          margin-bottom: 4rem;
        }
      }
    }
  }
`

const PartnersSlider = () => {
  const data = useStaticQuery(graphql`
    query Partners {
      wpPage(slug: { eq: "partners" }) {
        acfPartners {
          mediaPartners {
            title
            gallery {
              acfAttachment {
                link
              }
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const acf = { ...data.wpPage.acfPartners }

  return (
    <PartnersSliderStyles>
      {acf.mediaPartners ? (
        <div className="logos-wrap">
          {acf.mediaPartners.title ? <h2>{acf.mediaPartners.title}</h2> : null}

          {acf.mediaPartners.gallery && acf.mediaPartners.gallery.length ? (
            <div className="logos slider">
              <Swiper
                spaceBetween={60}
                slidesPerView={6}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                centerInsufficientSlides
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  480: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 6,
                    spaceBetween: 60,
                  },
                }}
              >
                {acf.mediaPartners.gallery.map(logo => {
                  return (
                    <React.Fragment>
                      {logo &&
                      logo.localFile &&
                      logo.localFile.childImageSharp &&
                      logo.localFile.childImageSharp.fluid ? (
                        <SwiperSlide>
                          {logo.acfAttachment && logo.acfAttachment.link ? (
                            <a
                              href={logo.acfAttachment.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Img
                                fluid={logo.localFile.childImageSharp.fluid}
                                alt={logo.altText}
                              />
                            </a>
                          ) : (
                            <Img
                              fluid={logo.localFile.childImageSharp.fluid}
                              alt={logo.altText}
                            />
                          )}
                        </SwiperSlide>
                      ) : null}
                    </React.Fragment>
                  )
                })}
                <div className="swiper-button-prev">
                  <FaChevronLeft />
                </div>
                <div className="swiper-button-next">
                  <FaChevronRight />
                </div>
              </Swiper>
            </div>
          ) : null}
        </div>
      ) : null}
    </PartnersSliderStyles>
  )
}

export default PartnersSlider
