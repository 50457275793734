import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { BsArrowRight } from "react-icons/bs"

const ResourceCategoryStyles = styled.section`
  .category {
    transition: max-height 0.3s ease;

    &.hide {
      max-height: 0;
      overflow: hidden;
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  .content {
    margin: 9rem auto;
    text-align: center;

    h2 {
      font-weight: 600;
      font-size: 3.6rem;
      line-height: 4.8rem;
      margin-bottom: 3rem;
    }

    p {
      font-weight: 500;
      font-size: 2.1rem;
      line-height: 3.2rem;
      margin: 3rem 0 4rem;
    }

    .btn {
      margin: 0;
    }
  }

  .post-list {
    padding: 0 6rem;
    margin-bottom: 9rem;

    img {
      display: block;
    }

    .gatsby-image-wrapper {
      /* padding-top: 66.6666%; */
    }

    .row {
      margin: 0 -3rem;

      .col {
        margin: 0 3rem;
      }
    }

    a {
      color: var(--body);

      &:hover {
        color: var(--blue);
      }
    }

    h3 {
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin: 3rem 0;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 3rem 0;
    }

    .more {
      color: var(--blue);
      font-size: 1.6rem;
      line-height: 2.1rem;
      font-weight: 600;

      svg {
        vertical-align: middle;
      }

      &:hover {
        color: var(--body);
      }
    }

    .sub-title {
      margin-bottom: 1.5rem;
    }
  }

  .resources {
    margin: 12rem 6rem;
    padding: 16rem 3rem;
    background-color: var(--tg);

    .row {
      .col {
        margin: 0 3rem;
        flex: 1 1 calc(40% - 6rem);

        &:last-child {
          flex: 1 1 calc(60% - 6rem);
        }

        h2 {
          text-align: center;
          font-weight: 600;
          font-size: 3.6rem;
          line-height: 4.8rem;
          margin-top: 0;
        }

        p {
          text-align: center;
          font-size: 2.1rem;
          line-height: 3.2rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          color: var(--heading);
          display: block;
          margin-bottom: 4rem;
          padding-bottom: 1rem;
          border-bottom: 2px solid var(--heading);
          transition: border-color 0.3s ease;
          transition: color 0.3s ease;
          text-transform: uppercase;

          &:hover {
            color: var(--blue);
            border-color: var(--blue);
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        h3 {
          margin: 0;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 2.2rem;
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .post-list {
      .row {
        .col {
          margin-bottom: 6rem;

          img {
            width: 100%;
          }
        }
      }
    }

    .resources {
      .row {
        .col {
          flex: 1 1 100%;
          margin-bottom: 6rem;

          &:last-child {
            flex: 1 1 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
`

const ResourceCategory = ({ data }) => {
  const posts = useStaticQuery(graphql`
    query CategoryPosts {
      allWpPost {
        edges {
          node {
            title
            slug
            excerpt
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                sourceUrl
              }
            }
            tags {
              nodes {
                name
              }
            }
            categories {
              nodes {
                slug
              }
            }
            acfResource {
              file {
                guid
              }
            }
          }
        }
      }
    }
  `)

  let count = 0

  const filteredPosts = posts.allWpPost.edges.filter(post => {
    const categories = post.node.categories.nodes.map(category => {
      return category.slug
    })

    if (categories.includes(data.slug)) {
      count++

      if (data.slug !== "resources") {
        if (count <= 3) {
          return post
        }
      } else {
        return post
      }
    }
  })

  return (
    <ResourceCategoryStyles>
      {data.slug !== "resources" ? (
        <div className={`category category-${data.slug}`}>
          <div className="content container">
            {data.name ? <h2>{data.name}</h2> : null}

            {data.description ? (
              <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
            ) : null}

            {data.uri ? (
              <Link to={data.uri} className="btn">
                View more
              </Link>
            ) : null}
          </div>

          {filteredPosts && filteredPosts.length ? (
            <div className="post-list container container-full">
              <div className="row">
                {filteredPosts.map(post => {
                  return (
                    <div className="col">
                      {post.node.tags && post.node.tags.nodes.length ? (
                        <div className="sub-title">
                          {post.node.tags.nodes[0].name}
                        </div>
                      ) : null}
                      <Link to={`/${post.node.slug}`}>
                        {post.node.featuredImage &&
                        post.node.featuredImage.node &&
                        post.node.featuredImage.node.localFile &&
                        post.node.featuredImage.node.localFile
                          .childImageSharp &&
                        post.node.featuredImage.node.localFile.childImageSharp
                          .fluid ? (
                          <Img
                            fluid={
                              post.node.featuredImage.node.localFile
                                .childImageSharp.fluid
                            }
                            alt={post.node.featuredImage.node.altText}
                          />
                        ) : null}
                      </Link>

                      <Link to={`/${post.node.slug}`}>
                        <h3>{post.node.title}</h3>
                      </Link>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.node.excerpt,
                        }}
                      ></div>

                      <Link to={`/${post.node.slug}`} className="more">
                        Read more <BsArrowRight />
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className={`resources container container-full category category-${data.slug}`}
        >
          <div className="row">
            <div className="col">
              {data.name ? <h2>{data.name}</h2> : null}

              {data.description ? (
                <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
              ) : null}
            </div>

            {filteredPosts && filteredPosts.length ? (
              <div className="col">
                {filteredPosts.map(post => {
                  return (
                    <React.Fragment>
                      {post.node.acfResource &&
                      post.node.acfResource.file &&
                      post.node.acfResource.file.guid ? (
                        <a
                          href={post.node.acfResource.file.guid}
                          target="_blank"
                          rel="noreferrer"
                          className="more"
                        >
                          <h3>{post.node.title}</h3>
                        </a>
                      ) : (
                        <Link to={`/${post.node.slug}`} className="more">
                          <h3>{post.node.title}</h3>
                        </Link>
                      )}
                    </React.Fragment>
                  )
                })}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </ResourceCategoryStyles>
  )
}

export default ResourceCategory
