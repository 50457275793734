import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import PageHeader from "../components/page-header"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PartnersSlider from "./partners-slider"

SwiperCore.use([Navigation])

const PartnersStyles = styled.section`
  .container-full {
    padding: 0 10%;
  }

  .get-in-touch {
    padding: 9rem 10%;

    .row {
      align-items: center;

      .col {
        flex: 1 1 60%;

        p {
          font-weight: 600;
          font-size: 2.2vw;
          line-height: 1.2;
          margin: 0;
        }

        &:last-child {
          flex: 1 1 calc(40% - 10rem);
          margin-left: 10rem;
        }
      }

      .contact {
        color: #fff;
        padding: 3.5rem 4.5rem;
        background-color: var(--dblue);

        p {
          font-weight: 700;
          font-size: 2.1rem;
          line-height: 1.5;

          a {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .partners {
    .partner {
      padding: 9rem 0;

      &:nth-child(odd) {
        background-color: var(--tg);
      }

      h2 {
        font-weight: 600;
        font-size: 1.8vw;
        line-height: 1.2;
      }

      p,
      ul {
        font-size: 2.2rem;
        line-height: 1.8;
      }

      .row {
        align-items: stretch;

        img {
          display: block;
        }

        .col {
          flex: 1 1 66.6666%;
          display: flex;
          flex-direction: column;
          align-items: stretch;

          &:last-child {
            flex: 1 1 calc(33.3333% - 10rem);
            margin-left: 10rem;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .gatsby-image-wrapper {
              width: 60%;
              margin: 0 auto;
            }

            /* a,
            .gatsby-image-wrapper {
              padding-top: 0;
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: center;

              img {
                display: none !important;
                height: auto !important;
              }

              picture {
                img {
                  display: block !important;
                  position: relative !important;
                }
              }
            } */
          }
        }
      }
    }
  }

  .logos-wrap {
    padding: 0 6rem 16rem;

    h2 {
      text-align: center;
      margin-bottom: 4rem;
      font-weight: 700;
      font-size: 1.5vw;
      line-height: 1.2;
    }

    .logos {
      .swiper-container {
        /* width: auto; */

        .swiper-button-disabled {
          display: none;
        }

        .swiper-wrapper {
          align-items: center;
        }
      }

      a {
        img {
          display: block;
          filter: grayscale(100%);
          transition: filter 0.3s ease;

          &:hover {
            filter: none;
          }
        }

        .gatsby-image-wrapper {
          width: 50%;
          margin: 0 auto;
        }

        /* .gatsby-image-wrapper {
          padding-top: 0;

          img {
            display: none !important;
            height: auto !important;
          }

          picture {
            img {
              display: block !important;
              position: relative !important;
            }
          }
        } */
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .get-in-touch {
      .row {
        .col {
          flex: 1 1 100%;

          p {
            font-size: 3rem;
          }

          &:last-child {
            margin: 6rem 0 0;

            p {
              font-size: 2.1rem;
            }
          }
        }
      }
    }

    .partners {
      .partner {
        .row {
          .col {
            flex: 1 1 100%;

            &:last-child {
              margin-top: 6rem;
              margin-left: 0;

              .gatsby-image-wrapper {
                width: 50%;
              }
            }
          }
        }

        h2 {
          font-size: 3rem;
        }
      }
    }

    .logos-wrap {
      h2 {
        font-size: 3rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .partners {
      .partner {
        .row {
          .col {
            flex: 1 1 100%;

            &:last-child {
              margin: 6rem 0 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .logos-wrap {
      .logos {
        flex-wrap: wrap;

        a {
          flex: 1 1 100%;
          margin-bottom: 4rem;
        }
      }
    }
  }
`

const Partners = ({ data }) => {
  const acf = { ...data.acfPartners }

  return (
    <PartnersStyles>
      <PageHeader data={acf.header} />

      <div className="get-in-touch container container-full">
        <div className="row">
          <div className="col">
            {acf.support.title ? <p>{acf.support.title}</p> : null}
          </div>
          <div className="col">
            {acf.support.contact ? (
              <div
                className="contact"
                dangerouslySetInnerHTML={{ __html: acf.support.contact }}
              ></div>
            ) : null}
          </div>
        </div>
      </div>

      {acf.partners && acf.partners.length ? (
        <div className="partners">
          {acf.partners.map(partner => {
            return (
              <div className="partner">
                <div className="container container-full">
                  <div className="row">
                    <div className="col">
                      {partner.title ? <h2>{partner.title}</h2> : null}

                      {partner.content ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: partner.content }}
                        ></div>
                      ) : null}
                    </div>
                    <div className="col">
                      {partner.image.image &&
                      partner.image.image.localFile &&
                      partner.image.image.localFile.childImageSharp &&
                      partner.image.image.localFile.childImageSharp.fluid ? (
                        <React.Fragment>
                          {partner.image.link ? (
                            <a
                              href={partner.image.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Img
                                fluid={
                                  partner.image.image.localFile.childImageSharp
                                    .fluid
                                }
                                alt={partner.image.image.altText}
                              />
                            </a>
                          ) : (
                            <Img
                              fluid={
                                partner.image.image.localFile.childImageSharp
                                  .fluid
                              }
                              alt={partner.image.image.altText}
                            />
                          )}
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ) : null}

      {acf.associations ? (
        <div className="logos-wrap">
          {acf.associations.title ? <h2>{acf.associations.title}</h2> : null}

          {acf.associations.gallery && acf.associations.gallery.length ? (
            <div className="logos slider">
              <Swiper
                spaceBetween={60}
                slidesPerView={6}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                centerInsufficientSlides
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  480: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 6,
                    spaceBetween: 60,
                  },
                }}
              >
                {acf.associations.gallery.map(logo => {
                  return (
                    <React.Fragment>
                      {logo &&
                      logo.localFile &&
                      logo.localFile.childImageSharp &&
                      logo.localFile.childImageSharp.fluid ? (
                        <SwiperSlide>
                          {logo.acfAttachment && logo.acfAttachment.link ? (
                            <a
                              href={logo.acfAttachment.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Img
                                fluid={logo.localFile.childImageSharp.fluid}
                                alt={logo.altText}
                              />
                            </a>
                          ) : (
                            <Img
                              fluid={logo.localFile.childImageSharp.fluid}
                              alt={logo.altText}
                            />
                          )}
                        </SwiperSlide>
                      ) : null}
                    </React.Fragment>
                  )
                })}
                <div className="swiper-button-prev">
                  <FaChevronLeft />
                </div>
                <div className="swiper-button-next">
                  <FaChevronRight />
                </div>
              </Swiper>
            </div>
          ) : null}
        </div>
      ) : null}

      <PartnersSlider />
    </PartnersStyles>
  )
}

export default Partners
