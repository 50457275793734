import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { BsArrowRight } from "react-icons/bs"

const ResourcesStyles = styled.section`
  .resources {
    padding: 0 12rem;
    margin-bottom: 9rem;

    .row {
      margin: 0 -3rem;

      .col {
        margin: 0 3rem;
      }
    }

    img {
      display: block;
    }

    .gatsby-image-wrapper {
      /* padding-top: 66.6666%; */
    }

    a {
      color: var(--body);

      &:hover {
        color: var(--blue);
      }
    }

    h3 {
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.5;
      margin: 3rem 0;
    }

    p {
      font-size: 1.8rem;
      line-height: 1.7;
      margin: 3rem 0;
    }

    .more {
      color: var(--blue);
      font-size: 1.6rem;
      line-height: 2.1rem;
      font-weight: 600;

      svg {
        vertical-align: middle;
      }

      &:hover {
        color: var(--body);
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .resources {
      .row {
        .col {
          margin-bottom: 6rem;

          img {
            width: 100%;
          }
        }
      }
    }
  }
`

const Resources = ({ data }) => {
  const acfCategories =
    data && data.length
      ? data.map(cat => {
          return cat.slug
        })
      : null

  const posts = useStaticQuery(graphql`
    query ResourcesPosts {
      allWpPost {
        edges {
          node {
            title
            slug
            excerpt
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                sourceUrl
              }
            }
            tags {
              nodes {
                name
              }
            }
            categories {
              nodes {
                slug
              }
            }
            acfResource {
              file {
                guid
              }
            }
          }
        }
      }
    }
  `)

  let count = 0

  const filteredPosts = posts.allWpPost.edges.filter(post => {
    if (acfCategories) {
      const categories = post.node.categories.nodes.map(category => {
        return category.slug
      })

      const found = categories.some(cat => acfCategories.includes(cat))

      if (found) {
        count++

        if (count <= 3) {
          return post
        }
      }
    } else {
      count++

      if (count <= 3) {
        return post
      }
    }
  })

  return (
    <ResourcesStyles>
      {filteredPosts && filteredPosts.length ? (
        <div className="resources container container-full">
          <div className="row">
            {filteredPosts.map(post => {
              return (
                <div className="col">
                  <Link to={`/${post.node.slug}`}>
                    {post.node.featuredImage &&
                    post.node.featuredImage.node &&
                    post.node.featuredImage.node.localFile &&
                    post.node.featuredImage.node.localFile.childImageSharp &&
                    post.node.featuredImage.node.localFile.childImageSharp
                      .fluid ? (
                      <Img
                        fluid={
                          post.node.featuredImage.node.localFile.childImageSharp
                            .fluid
                        }
                        alt={post.node.featuredImage.node.altText}
                      />
                    ) : null}
                  </Link>

                  <Link to={`/${post.node.slug}`}>
                    <h3>{post.node.title}</h3>
                  </Link>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.node.excerpt,
                    }}
                  ></div>

                  <Link to={`/${post.node.slug}`} className="more">
                    Read more <BsArrowRight />
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}
    </ResourcesStyles>
  )
}

export default Resources
