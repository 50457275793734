import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import PageHeader from "../components/page-header"
import Banner from "../components/banner"

const IndustriesStyles = styled.section`
  .page-header h1 {
    color: #fff;
  }

  .content {
    margin: 15rem auto;
    text-align: center;

    p {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 1.5vw;
      line-height: 1.4;
    }
  }

  .waste {
    background-size: cover;
    background-position: calc(25% + 25vw) center;
    background-repeat: no-repeat;
    padding: 5% 10%;
    margin: 15rem auto;
    position: relative;

    .background-image {
      left: 25%;
      width: 75%;
    }

    .row {
      .col {
        flex: 0 0 48%;
        background-color: #262626;
        color: #fff;
        padding: 10rem 6rem;
        box-sizing: border-box;

        h2 {
          margin: 0 0 3rem;
          font-weight: 700;
          font-size: 1.8vw;
          line-height: 1.7;
        }

        p,
        ul {
          font-size: 2rem;
          line-height: 1.9;
          font-weight: 500;
          margin: 0 0 3rem;

          a {
            color: #fff;

            &:hover {
              color: var(--blue);
            }
          }
        }

        .btn {
          margin: 0;
        }
      }
    }
  }

  .commercial {
    background-size: cover;
    background-position: center;
    padding: 0;
    margin: 15rem auto;
    position: relative;

    .row {
      justify-content: center;

      .col {
        position: relative;
        box-sizing: border-box;
      }

      .col-content {
        flex: 0 0 33.3333%;
        background-color: #262626;
        color: #fff;
        padding: 10rem 6rem;

        h2 {
          margin: 0 0 3rem;
          font-weight: 700;
          font-size: 1.8vw;
          line-height: 1.7;
        }

        p,
        ul {
          font-size: 2rem;
          line-height: 1.5;
          font-weight: 500;
          margin: 0 0 3rem;

          a {
            color: #fff;

            &:hover {
              color: var(--blue);
            }
          }
        }

        .btn {
          margin: 0;
        }
      }
    }
  }

  .services {
    padding: 0 3rem;
    margin: 15rem auto;

    .row {
      .col {
        padding: 0 3rem;

        .service-content {
          padding: 0 8%;
        }

        h2 {
          margin: 2rem 0;
          font-weight: 700;
          font-size: 1.5vw;
          line-height: 1.7;
        }

        p,
        ul {
          font-size: 2rem;
          line-height: 1.5;
          font-weight: 500;
          margin: 0 0 3rem;
        }

        ul {
          padding-left: 2rem;
        }

        .btn {
          margin: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .content p {
      font-size: 2.5rem;
    }

    .services {
      padding: 0 10%;

      .row {
        margin-left: -3rem;
        margin-right: -3rem;

        .col {
          margin-bottom: 6rem;

          img {
            width: 100%;
          }
        }
      }
    }

    .waste {
      .row {
        .col {
          flex: 1 1 100%;

          h2 {
            font-size: 3rem;
          }
        }
      }
    }

    .commercial {
      .row {
        .col {
          flex: 1 1 25%;

          h2 {
            font-size: 3rem;
          }
        }

        .col-content {
          flex: 1 1 50%;
        }
      }
    }

    .services {
      .row {
        .col {
          h2 {
            font-size: 3rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .commercial,
    .waste {
      padding: 0 10%;
      margin-left: auto;
      background-position: center;

      .background-image {
        display: none;
      }

      .row {
        margin-left: auto;

        .col {
          flex: 1 1 100%;
        }
      }
    }
  }
`

const Industries = ({ data }) => {
  const acf = { ...data.acfIndustries }

  return (
    <IndustriesStyles>
      <PageHeader data={acf.header} />

      {acf.content ? (
        <div className="content container container-full">
          {acf.content ? (
            <div dangerouslySetInnerHTML={{ __html: acf.content }}></div>
          ) : null}
        </div>
      ) : null}

      <div className="waste container container-full">
        {acf.waste.background &&
        acf.waste.background.localFile &&
        acf.waste.background.localFile.childImageSharp &&
        acf.waste.background.localFile.childImageSharp.fluid ? (
          <div className="background-image">
            <Img
              fluid={acf.waste.background.localFile.childImageSharp.fluid}
              alt={acf.waste.background.altText}
            />
          </div>
        ) : null}

        <div className="row">
          <div className="col">
            {acf.waste.title ? <h2>{acf.waste.title}</h2> : null}

            {acf.waste.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.waste.content }}
              ></div>
            ) : null}

            {acf.waste.button &&
            acf.waste.button.title &&
            acf.waste.button.link ? (
              <Link to={acf.waste.button.link} className="btn btn-light">
                {acf.waste.button.title}
              </Link>
            ) : null}
          </div>
        </div>
      </div>

      <div className="commercial container container-full">
        <div className="row">
          <div className="col">
            {acf.commercial.imageLeft &&
            acf.commercial.imageLeft.localFile &&
            acf.commercial.imageLeft.localFile.childImageSharp &&
            acf.commercial.imageLeft.localFile.childImageSharp.fluid ? (
              <div className="background-image">
                <Img
                  fluid={
                    acf.commercial.imageLeft.localFile.childImageSharp.fluid
                  }
                  alt={acf.commercial.imageLeft.altText}
                />
              </div>
            ) : null}
          </div>
          <div className="col col-content">
            {acf.commercial.title ? <h2>{acf.commercial.title}</h2> : null}

            {acf.commercial.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.commercial.content }}
              ></div>
            ) : null}

            {acf.commercial.button &&
            acf.commercial.button.title &&
            acf.commercial.button.link ? (
              <Link to={acf.commercial.button.link} className="btn btn-light">
                {acf.commercial.button.title}
              </Link>
            ) : null}
          </div>
          <div className="col">
            {acf.commercial.imageRight &&
            acf.commercial.imageRight.localFile &&
            acf.commercial.imageRight.localFile.childImageSharp &&
            acf.commercial.imageRight.localFile.childImageSharp.fluid ? (
              <div className="background-image">
                <Img
                  fluid={
                    acf.commercial.imageRight.localFile.childImageSharp.fluid
                  }
                  alt={acf.commercial.imageRight.altText}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {acf.services && acf.services.length ? (
        <div className="services container container-full">
          <div className="row">
            {acf.services.map(col => {
              return (
                <div className="col">
                  {col.image &&
                  col.image.localFile &&
                  col.image.localFile.childImageSharp &&
                  col.image.localFile.childImageSharp.fluid ? (
                    <Img
                      fluid={col.image.localFile.childImageSharp.fluid}
                      alt={col.image.altText}
                    />
                  ) : null}

                  <div class="service-content">
                    {col.title ? <h2>{col.title}</h2> : null}
                    {col.content ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: col.content }}
                      ></div>
                    ) : null}
                    {col.button && col.button.title && col.button.link ? (
                      <Link to={col.button.link} className="btn">
                        {col.button.title}
                      </Link>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}

      <Banner data={acf.banner} />
    </IndustriesStyles>
  )
}

export default Industries
