import React, { useState } from "react"
import styled from "styled-components"
import { BiPlus } from "react-icons/bi"
import { BiMinus } from "react-icons/bi"

const ToggleStyles = styled.section`
  .toggle {
    border-bottom: 1px solid #fff;

    button {
      background: none;
      border: 0;
      outline: 0;
      padding: 2rem 0;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 4.5rem;
      color: #fff;
      display: inline-block;
      width: 100%;
      text-align: left;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > * {
        pointer-events: none;
      }
    }

    svg {
      width: 14px;
      height: 14px;
      padding: 2px;
      border-radius: 100%;
      background-color: #fff;
      color: #000;
    }

    .toggle-content {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;

      p {
        margin: 0 0 3.5rem;
        transition: max-height 0.3s ease;
      }

      &.hide {
        p {
          margin: 0;
          max-height: 0;
          overflow: hidden;
        }
      }
    }
  }
`

const Toggle = ({ data }) => {
  const [Item, setItem] = useState(0)

  const handleToggle = event => {
    const active = Item ? 0 : 1
    const content = event.target.nextElementSibling.querySelector("p")

    if (active) {
      content.style.maxHeight = content.scrollHeight + "px"
    } else {
      content.style.maxHeight = null
    }

    setItem(active)
  }

  return (
    <ToggleStyles>
      {data.toggleTitle && data.toggleContent ? (
        <div className="toggle">
          <button className="toggle-heading" onClick={handleToggle}>
            {data.toggleTitle}
            {Item ? <BiMinus /> : <BiPlus />}
          </button>

          <div
            className={`toggle-content ${Item ? "" : "hide"}`}
            dangerouslySetInnerHTML={{ __html: data.toggleContent }}
          ></div>
        </div>
      ) : null}
    </ToggleStyles>
  )
}

export default Toggle
