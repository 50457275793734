import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import Layout from "../components/layout"
import Home from "../components/home"
import About from "../components/about"
import Partners from "../components/partners"
import Team from "../components/team"
import Industries from "../components/industries"
import Solutions from "../components/solutions"
import Mitigation from "../components/mitigation"
import Emissions from "../components/emissions"
import DefaultPage from "../components/default"
import IntelligentSystems from "../components/intelligent-systems"
import Contact from "../components/contact"
import ClientPortal from "../components/client-portal"
import Blog from "../components/blog"
import Category from "../components/category"
import Post from "../components/post"
import Mt from "../components/mt"
import Faq from "../components/faq"

const PageTemplate = ({ data: { page } }) => {
  const pages = {
    home: Home,
    about: About,
    partners: Partners,
    team: Team,
    industries: Industries,
    solutions: Solutions,
    "mitigation-technologies": Mitigation,
    "emissions-analysis": Emissions,
    "intelligent-systems": IntelligentSystems,
    contact: Contact,
    "client-portal": ClientPortal,
    resources: Blog,
    category: Category,
    post: Post,
    "mt-6-molecular-filtration-system": Mt,
    faq: Faq,
  }

  return (
    <Layout>
      <Seo post={page} />
      {page.slug && page.slug in pages ? (
        React.createElement(pages[page.slug], { data: page }, null)
      ) : (
        <DefaultPage data={page} />
      )}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      slug
      title
      content
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfHome {
        video {
          guid
        }
        banner {
          background {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          button {
            link
            title
          }
          content
          subTitle
          title
        }
        solutions {
          content
          title
        }
        technology {
          button {
            link
            title
          }
          content
          gallery {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          subTitle
          title
        }
        toggles {
          buttons {
            link
            title
            toggle
            toggleContent
          }
          content
          image {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          subTitle
          title
        }
      }
      acfAbout {
        banner {
          background {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          button {
            link
            title
          }
          content
          subTitle
          title
        }
        topBanner {
          background {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          subTitle
          title
        }
        content {
          content
          title
          vision
        }
        services {
          columns {
            title
            content
            button {
              link
              title
            }
          }
          background {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          backgroundVideo {
            guid
          }
        }
        team {
          subTitle
          title
        }
      }
      acfPartners {
        associations {
          title
          gallery {
            acfAttachment {
              link
            }
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        header {
          background {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          content
          subTitle
          title
        }
        mediaPartners {
          title
          gallery {
            acfAttachment {
              link
            }
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        partners {
          content
          title
          image {
            link
            image {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        support {
          contact
          title
        }
      }
      acfIndustries {
        banner {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
          button {
            title
            link
          }
        }
        commercial {
          imageLeft {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          imageRight {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          button {
            link
            title
          }
          content
          title
        }
        content
        header {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
        }
        services {
          button {
            link
            title
          }
          content
          title
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
        }
        waste {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          button {
            link
            title
          }
          content
          title
        }
      }
      acfSolutions {
        banner {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
          button {
            title
            link
          }
        }

        header {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
        }
        solutions {
          title
          solutions {
            button {
              link
              title
            }
            content
            title
            subTitle
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              sourceUrl
            }
          }
        }
      }
      acfMitigation {
        header {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
        }
        industries {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          button {
            link
            title
          }
          content
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          title
        }
        odor {
          content
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          subTitle
          title
        }
        resources {
          button {
            title
            link
          }
          content
          title
          categories {
            slug
          }
        }
        services {
          button {
            link
            title
          }
          content
          services {
            content
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              sourceUrl
            }
            title
          }
          title
        }
        solutions {
          button {
            link
            title
          }
          content
          title
          toggles {
            buttons {
              link
              title
              toggle
              toggleContent
            }
            content
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              sourceUrl
            }
            subTitle
            title
          }
        }
        vapor {
          content
          title
          versus {
            content
            title
          }
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          backgroundVideo {
            guid
          }
        }
      }
      acfEmissions {
        banner {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
        }
        content {
          button {
            link
            title
          }
          content
          title
        }
        doctors {
          leftColumn {
            content
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              sourceUrl
            }
            position
            title
          }
          rightColumn {
            content
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              sourceUrl
            }
            position
            title
          }
        }
        header {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
        }
        resources {
          button {
            link
            title
          }
          content
          title
          categories {
            slug
          }
        }
        services {
          button {
            link
            title
          }
          content
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          subTitle
          title
        }
        toggles {
          content
          title
          toggleContent
          toggleTitle
        }
      }
      acfIntel {
        banner {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          button {
            link
            title
          }
          content
          subTitle
          title
        }
        empower {
          content
          title
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
        }
        header {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
        }
        services {
          content
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          title
        }
        title
      }
      acfMt {
        about {
          button {
            link
            title
          }
          content
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          title
        }
        banner {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          button {
            link
            title
          }
          content
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          subTitle
          title
        }
        benefits {
          button {
            link
            title
          }
          caption
          content
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          title
        }
        content {
          content
          title
        }
        header {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
        }
        resources {
          button {
            link
            title
          }
          content
          title
          categories {
            slug
          }
        }
        solutions {
          button {
            link
            title
          }
          content
          solutions {
            content
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              sourceUrl
            }
            title
          }
          title
        }
      }
      acfContact {
        form {
          submitProjectGfFormId
          title
        }
        header {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          info {
            content
            title
          }
          subTitle
          title
        }
      }
      acfPortal {
        header {
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
          content
          subTitle
          title
        }
        content {
          content
          title
        }
      }
      acfResources {
        header {
          title
          slides {
            slide
          }
        }
        content {
          content
          title
        }
        categories {
          name
          slug
          uri
          databaseId
          description
        }
      }
      acfFaq {
        header {
          content
          subTitle
          title
          background {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            sourceUrl
          }
        }
        toggles {
          toggleContent
          toggleTitle
        }
        gallery {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          sourceUrl
        }
      }
    }
  }
`
