import React, { useEffect } from "react"
import styled from "styled-components"
import PageHeader from "./page-header"

const ClientPortalStyles = styled.section`
  .portal-login {
    margin: 12rem 0;

    .row {
      align-items: center;
      margin: 0 -3rem;

      .col {
        margin: 0 3rem;

        h2 {
          font-weight: 600;
          font-size: 3.6rem;
          line-height: 5.1rem;
          margin: 0;
        }

        p {
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 2.4rem;
          margin: 0 0 1rem;
        }

        input {
          width: 100%;
          height: 70px;
          border: 0;
          background-color: var(--bd);
          padding: 2rem;
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .portal-login {
      .row {
        .col {
          p {
            margin-top: 6rem;
          }
        }
      }
    }
  }
`

const ClientPortal = ({ data }) => {
  const acf = { ...data.acfPortal }

  useEffect(() => {
    document.querySelector(".client-password").focus()
  })

  return (
    <ClientPortalStyles>
      <PageHeader data={acf.header} />

      <div className="portal-login container container-full">
        <div className="row">
          <div className="col">
            {acf.content.title ? <h2>{acf.content.title}</h2> : null}
          </div>
          <div className="col">
            {acf.content.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.content.content }}
              ></div>
            ) : null}

            <form action="#">
              <input
                className="client-password"
                type="password"
                title="Wrong Password"
                maxLength="20"
                name="password"
                pattern="[A-Za-z0-9]{99}"
                required
              />
            </form>
          </div>
        </div>
      </div>
    </ClientPortalStyles>
  )
}

export default ClientPortal
