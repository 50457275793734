import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import PageHeader from "./page-header"
import Toggle from "./toggle"

const FaqStyles = styled.section`
  .toggles {
    margin: 9rem auto;
    counter-reset: toggle;

    .toggle {
      color: var(--grey);
      border-bottom: 1px solid var(--grey);
      padding: 0 2rem;
      margin-top: 3rem;

      .toggle-heading {
        color: var(--grey);
        font-size: 2rem;
        font-weight: 500;
        justify-content: flex-start;
        align-items: flex-start;

        &:before {
          counter-increment: toggle;
          content: counter(toggle) ".";
          display: inline-block;
          margin-right: 0.5rem;
        }

        svg {
          background-color: var(--grey);
          color: #fff;
          margin-left: auto;
          margin-top: 1.2rem;
          flex: 1 1 14px;
          max-width: 14px;
          min-width: 14px;
        }
      }

      .toggle-content {
        font-size: 1.5rem;
        line-height: 1.7;
      }
    }
  }

  .gallery {
    display: flex;
    padding: 9rem 0;

    .gatsby-image-wrapper {
      flex: 1;
    }
  }

  @media screen and (max-width: 1080px) {
    .gallery {
      flex-wrap: wrap;

      .gatsby-image-wrapper {
        flex: 1 1 50%;
      }
    }
  }
`

const Faq = ({ data }) => {
  const acf = { ...data.acfFaq }

  return (
    <FaqStyles>
      <PageHeader data={acf.header} />

      {acf.toggles && acf.toggles.length ? (
        <div className="toggles container container-full">
          {acf.toggles.map(toggle => {
            return <Toggle data={toggle} />
          })}
        </div>
      ) : null}

      {acf.gallery && acf.gallery.length ? (
        <div className="gallery">
          {acf.gallery.map(image => {
            return (
              <Img
                fluid={image.localFile.childImageSharp.fluid}
                alt={image.altText}
              />
            )
          })}
        </div>
      ) : null}
    </FaqStyles>
  )
}

export default Faq
