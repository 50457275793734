import React from "react"
import styled from "styled-components"
import { VscSearch } from "react-icons/vsc"

import ResourceCategory from "./resource-category"
import Featured from "./featured"
import PartnersSlider from "./partners-slider"

const BlogStyles = styled.section`
  .blog-header {
    background-color: var(--grey);
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2.1rem;
    line-height: 2.8rem;
    letter-spacing: 2rem;
    text-align: center;
    padding: 6rem;
    display: flex;
    flex-wrap: wrap;

    div {
      flex: 1 1 calc(50% - 1.5rem);
      padding: 0 0.75rem;
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }
      11.11% {
        opacity: 1;
      }
      33.33% {
        opacity: 0;
      }
      44.44% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    .title {
      text-align: right;
    }

    .slides {
      position: relative;
      text-align: left;

      span {
        position: absolute;
        color: var(--blue);
        opacity: 0;
        animation-name: fade;
        animation-duration: 16s;
        animation-iteration-count: infinite;

        &:nth-child(1) {
          animation-delay: 0s;
        }

        &:nth-child(2) {
          animation-delay: 4s;
        }

        &:nth-child(3) {
          animation-delay: 8s;
        }

        &:nth-child(4) {
          animation-delay: 12s;
        }
      }
    }
  }

  .content {
    margin: 9rem auto;
    text-align: center;

    h2 {
      font-weight: 600;
      font-size: 3.6rem;
      line-height: 4.8rem;
      margin-bottom: 3rem;
    }

    p {
      font-weight: 500;
      font-size: 2.1rem;
      line-height: 3.2rem;
      margin: 3rem 0 4rem;
    }

    .btn {
      margin: 0;
    }
  }

  .filters {
    margin: 9rem 0 0;

    .row {
      justify-content: space-between;
      align-items: center;

      .col {
        flex: 1 1 70%;

        &:last-child {
          flex: 1 1 30%;
        }

        .btn {
          margin: 0 1rem 0 0;

          &.active {
            background: var(--blue);
            color: #fff;
            border-color: var(--blue);
          }
        }

        form {
          display: flex;

          input,
          button {
            padding: 1.3rem 2rem;
            background: none;
            color: var(--grey);
            border: 1px solid var(--grey);
            border-radius: 5px;
            font-size: 1.6rem;
            font-weight: 700;
            outline: 0;
            box-sizing: border-box;
            flex: 1;
          }

          input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: 0;
          }

          button {
            cursor: pointer;
            padding: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-width: 0;
            padding-right: 1.5rem;

            svg {
              color: var(--blue);
              width: 25px;
              height: 25px;
              float: right;
            }
          }
        }
      }
    }
  }

  .slider {
    padding: 0 6rem;
    margin: 12rem 0;
  }

  @media screen and (max-width: 1360px) {
    .filters {
      .row {
        .col {
          display: flex;
          flex: 1 1 100%;
          margin-bottom: 3rem;

          .btn {
            flex: 1;

            &:last-child {
              margin-right: 0;
            }
          }

          &:last-child {
            flex: 1 1 100%;

            form {
              flex: 1;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .filters {
      .row {
        margin: 0 -0.75rem;

        .col {
          flex-wrap: wrap;

          .btn {
            margin: 0 0.75rem 1.5rem;
            flex: 0 0 calc(50% - 1.5rem);
          }

          &:last-child {
            margin: 0 0.75rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    .blog-header {
      div {
        flex: 1 1 100%;

        &.title,
        &.slides {
          text-align: center;
          height: 28px;

          span {
            width: 100%;
            text-align: center;
            left: 0;
          }
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const acf = { ...data.acfResources }

  const handleFilter = event => {
    const button = event.target
    const buttons = document.querySelectorAll(".filters .btn")
    const category = button.dataset.category
    const categories = document.querySelectorAll(".category")

    if (button.classList.contains("active")) {
      ;[].forEach.call(categories, function (cat) {
        cat.classList.remove("hide")
        cat.style.maxHeight = cat.scrollHeight + "px"
      })
      ;[].forEach.call(buttons, function (but) {
        but.classList.remove("active")
      })
    } else {
      ;[].forEach.call(categories, function (cat) {
        cat.style.maxHeight = null
        cat.classList.add("hide")
      })
      ;[].forEach.call(buttons, function (but) {
        but.classList.remove("active")
      })

      document.querySelector(`.${category}`).classList.remove("hide")
      document.querySelector(`.${category}`).style.maxHeight =
        document.querySelector(`.${category}`).scrollHeight + "px"
      button.classList.add("active")
    }
  }

  return (
    <BlogStyles>
      {acf.header &&
      acf.header.title &&
      acf.header.slides &&
      acf.header.slides.length ? (
        <div className="blog-header">
          <div class="title">{acf.header.title}</div>
          <div className="slides">
            {acf.header.slides.map(slide => {
              return <span>{slide.slide}</span>
            })}
          </div>
        </div>
      ) : null}

      <div className="content container">
        {acf.content.title ? <h1>{acf.content.title}</h1> : null}

        {acf.content.content ? (
          <div dangerouslySetInnerHTML={{ __html: acf.content.content }}></div>
        ) : null}
      </div>

      <Featured />

      <div className="filters container container-full">
        <div className="row">
          {acf.categories && acf.categories.length ? (
            <div className="col">
              {acf.categories.map(category => {
                return (
                  <button
                    className="btn"
                    data-category={`category-${category.slug}`}
                    onClick={handleFilter}
                  >
                    {category.name}
                  </button>
                )
              })}
            </div>
          ) : null}
          <div className="col">
            <form action="/search/">
              <input type="text" name="s" placeholder="Search" />
              <button>
                <VscSearch />
              </button>
            </form>
          </div>
        </div>
      </div>

      {acf.categories && acf.categories.length ? (
        <div className="col">
          {acf.categories.map(category => {
            return <ResourceCategory data={category} />
          })}
        </div>
      ) : null}

      <PartnersSlider />
    </BlogStyles>
  )
}

export default Blog
