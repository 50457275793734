import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const BannerStyles = styled.section`
  .banner {
    position: relative;
    color: #fff;
    padding: 10% 0;
    /* background-color: var(--grey); */
    background-size: cover;
    background-position: center;
    overflow: hidden;

    .container {
      padding-left: 20%;
      padding-right: 20%;
    }

    .sub-title {
      color: #fff;
      font-size: 1.6rem;
      letter-spacing: 4px;
      line-height: 1.2;
    }

    h2 {
      font-size: 2.5vw;
      font-weight: 700;
      line-height: 1.2;
      margin: 3rem 0;
    }

    p {
      font-weight: 500;
      font-size: 2.2rem;
      line-height: 1.9;
      margin-bottom: 3rem;
    }

    .btn {
      margin: 1rem 0 0;
    }

    @media screen and (max-width: 1080px) {
      h2 {
        font-size: 2.5rem;
      }
    }
  }
`

const Banner = ({ data }) => {
  return (
    <BannerStyles>
      {data ? (
        <div className="banner">
          {data.background &&
          data.background.localFile &&
          data.background.localFile.childImageSharp &&
          data.background.localFile.childImageSharp.fluid ? (
            <div className="background-image">
              <Img
                fluid={data.background.localFile.childImageSharp.fluid}
                alt={data.background.altText}
              />
            </div>
          ) : null}

          <div className="container container-full">
            <div className="row">
              <div className="col">
                {data.subTitle ? (
                  <div className="sub-title">{data.subTitle}</div>
                ) : null}

                {data.title ? <h2>{data.title}</h2> : null}

                {data.content ? (
                  <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                ) : null}

                {data.button && data.button.title && data.button.link ? (
                  <Link to={data.button.link} className="btn btn-light">
                    {data.button.title}
                  </Link>
                ) : null}
              </div>

              {data.image &&
              data.image.localFile &&
              data.image.localFile.childImageSharp &&
              data.image.localFile.childImageSharp.fluid ? (
                <div className="col">
                  <Img
                    fluid={data.image.localFile.childImageSharp.fluid}
                    alt={data.image.altText}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </BannerStyles>
  )
}

export default Banner
