import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import PageHeader from "./page-header"
import Banner from "../components/banner"

const IntelligentSystemsStyles = styled.section`
  .page-header {
    .sub-title {
      color: var(--blue);
    }
  }

  .content {
    margin: 9rem auto;
    text-align: center;

    h2 {
      font-weight: 700;
      font-size: 2.2vw;
      line-height: 1.2;
      margin-bottom: 3rem;

      strong {
        font-weight: 700;
        color: var(--blue);
      }
    }

    p {
      font-weight: 500;
      font-size: 2.1rem;
      line-height: 3.2rem;
      margin: 3rem 0 4rem;
    }

    .btn {
      margin: 0;
    }
  }

  .empower {
    margin: 6rem auto;
    padding-left: 0;

    .row {
      align-items: center;

      .col {
        &:first-child {
          margin-right: 12rem;
        }

        img {
          display: block;
        }

        h2 {
          font-weight: 700;
          font-size: 2vw;
          line-height: 1.2;
          margin-bottom: 3rem;
        }

        p {
          font-weight: 500;
          font-size: 2rem;
          line-height: 1.8;
        }
      }
    }
  }

  .services {
    padding: 0;

    .row {
      .col {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        img {
          display: block;
        }

        .content-wrap {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          padding: 8rem 6rem;
          background-color: #1e1e1e;
          color: #fff;

          h3 {
            font-weight: 500;
            font-size: 2.6rem;
            line-height: 1.7;
            margin-top: 0;
          }

          p {
            font-weight: 500;
            font-size: 2rem;
            line-height: 1.7;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &:nth-child(2) {
          .content-wrap {
            background-color: var(--heading);
          }
        }

        &:last-child {
          .content-wrap {
            background-color: var(--body);

            /* a {
              color: var(--grey);
            } */
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .content h2 {
      font-size: 3rem;
    }

    .empower {
      img {
        width: 100%;
        margin-bottom: 3rem;
      }

      .row {
        .col {
          h2 {
            font-size: 2.5rem;
          }
        }

        .col:first-child {
          margin: 0;
        }

        .col:last-child {
          padding: 0 10%;
        }
      }
    }
  }
`

const IntelligentSystems = ({ data }) => {
  const acf = { ...data.acfIntel }

  return (
    <IntelligentSystemsStyles>
      <PageHeader data={acf.header} />

      <div className="empower container container-full">
        <div className="row">
          <div className="col">
            {acf.empower.image &&
            acf.empower.image.localFile &&
            acf.empower.image.localFile.childImageSharp &&
            acf.empower.image.localFile.childImageSharp.fluid ? (
              <Img
                fluid={acf.empower.image.localFile.childImageSharp.fluid}
                alt={acf.empower.image.altText}
              />
            ) : null}
          </div>
          <div className="col">
            {acf.empower.title ? <h2>{acf.empower.title}</h2> : null}

            {acf.empower.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.empower.content }}
              ></div>
            ) : null}
          </div>
        </div>
      </div>

      {acf.services && acf.services.length ? (
        <div className="services container container-full">
          <div className="row">
            {acf.services.map(col => {
              return (
                <div className="col">
                  {col.image &&
                  col.image.localFile &&
                  col.image.localFile.childImageSharp &&
                  col.image.localFile.childImageSharp.fluid ? (
                    <Img
                      fluid={col.image.localFile.childImageSharp.fluid}
                      alt={col.image.altText}
                    />
                  ) : null}

                  <div class="content-wrap">
                    {col.title ? <h3>{col.title}</h3> : null}

                    {col.content ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: col.content }}
                      ></div>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}

      <div className="content container">
        {acf.title ? (
          <h2 dangerouslySetInnerHTML={{ __html: acf.title }}></h2>
        ) : null}
      </div>

      <Banner data={acf.banner} />
    </IntelligentSystemsStyles>
  )
}

export default IntelligentSystems
