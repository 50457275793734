import React from "react"
import styled from "styled-components"

const DefaultPageStyles = styled.section`
  .page {
    padding: 5% 10%;

    .page-title {
      margin-bottom: 4.5rem;
    }

    p,
    ul,
    ol {
      line-height: 2.5rem;
    }
  }
`

const DefaultPage = ({ data }) => {
  return (
    <DefaultPageStyles>
      <div className="page container container-full">
        <h1 className="page-title">{data.title}</h1>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
    </DefaultPageStyles>
  )
}

export default DefaultPage
