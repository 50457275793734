import React, { useState, useLayoutEffect } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import ReactPlayer from "react-player/lazy"
import styled from "styled-components"
import PageHeader from "../components/page-header"
import Toggles from "./toggles"
import Resources from "../components/resources"

const MitigationStyles = styled.section`
  .content {
    margin: 9rem auto;
    text-align: center;

    h2 {
      font-weight: 600;
      font-size: 2vw;
      line-height: 1.7;
      margin-bottom: 4rem;
    }

    p {
      font-weight: 500;
      font-size: 2.2rem;
      line-height: 1.8;
      margin: 3rem 0 4rem;
    }

    .btn {
      margin: 0;
    }
  }

  .services {
    padding: 0 12rem;
    margin-bottom: 12rem;

    .row {
      margin: 0 -3rem;

      .col {
        margin: 0 3rem;

        .service-content {
          padding: 0 8%;
        }

        h3 {
          font-size: 1.5vw;
          line-height: 1.3;
          font-weight: 700;
        }

        p {
          font-weight: 500;
          font-size: 2rem;
          line-height: 1.5;
        }
      }
    }
  }

  .solutions {
    margin: 9rem 0;
    padding: 0 2rem;

    .solutions-content {
      max-width: 1080px;
      padding: 0 calc(10% - 2rem);
      margin: 0 auto;
    }

    h3 {
      margin-top: 0;
    }

    p {
      text-align: center;
      font-size: 2.2rem;
      color: var(--grey);
      line-height: 1.4;
      font-weight: 500;
      margin: 45px 0;
    }

    .col {
      padding-bottom: 25%;

      .featured-image {
        img {
          filter: grayscale(100%);
          transition: filter 0.3s ease !important;

          &:hover {
            filter: none;
          }
        }
      }

      .row {
        flex-wrap: wrap;

        .col {
          padding: 0;
          flex: 0 0 50%;
        }
      }
    }
  }

  .odors {
    background-color: var(--tg);
    padding: 9rem 0;
    margin-bottom: 12rem;

    h2 {
      font-weight: 600;
      font-size: 2vw;
      line-height: 1.2;
      margin-bottom: 4rem;
    }

    p {
      font-weight: 500;
      font-size: 2.2rem;
      line-height: 1.8;
      margin-bottom: 3rem;
    }

    h3 {
      position: relative;
      text-align: right;
      margin: 6rem 0 6rem;
      padding: 3rem 0 4rem;
      font-weight: 400;
      font-size: 0;
      letter-spacing: 4px;
      line-height: 1.7;
      opacity: 0;
      height: 28px;
      transition: opacity 0.75s ease;
      transition: font-size 0.75s ease;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--blue);
        transform: translateX(-100vw);
        transition: transform 1.5s ease;
      }

      &.animate {
        opacity: 1;
        font-size: 1.7rem;

        &:after {
          transform: translateX(0);
        }
      }
    }

    .container-full {
      padding: 0 15%;
      opacity: 0;
      transition: opacity 1s ease;

      &.animate {
        opacity: 1;
      }

      .row {
        margin: 0 3rem;

        .col {
          margin: 0 3rem;

          &:nth-child(2) {
            img {
              opacity: 0.6 !important;
            }
          }

          &:nth-child(3) {
            img {
              opacity: 0.06 !important;
            }
          }
        }
      }
    }

    /* .gatsby-image-wrapper {
      padding: 0;
      width: 100%;
      height: 100%;

      picture {
        img {
          position: relative !important;
        }
      }
    } */
  }

  .industries {
    background-size: cover;
    background-position: calc(25% + 25vw) center;
    background-repeat: no-repeat;
    padding: 5% 10%;
    margin: 15rem auto;
    position: relative;

    .background-image {
      left: 25%;
      width: 75%;
    }

    .row {
      .col {
        flex: 0 0 50%;
        padding: 6rem;
        box-sizing: border-box;

        &.col-content {
          background-color: var(--heading);
          color: #fff;
        }

        h2 {
          margin: 0 0 3rem;
          font-weight: 700;
          font-size: 2vw;
          line-height: 1.4;
        }

        p,
        ul {
          font-size: 2rem;
          line-height: 1.8;
          font-weight: 500;
          margin: 0 0 3rem;
        }

        .btn {
          margin: 0;
        }

        /* .gatsby-image-wrapper {
          padding: 0;

          picture {
            img {
              position: relative !important;
            }
          }
        } */
      }
    }
  }

  .vapor {
    padding: 12rem 0;
    color: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: 16rem;

    .player-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      z-index: -1;

      .react-player {
        video {
          position: absolute;
          min-width: 100%;
          min-height: 100%;
          width: auto !important;
          height: auto !important;
          top: 50%;
          left: 50%;
          object-fit: contain;
          transform: translate(-50%, -50%);
        }
      }
    }

    h2 {
      font-weight: 700;
      font-size: 2vw;
      line-height: 1.2;
      margin-bottom: 3rem;
    }

    p {
      font-weight: 500;
      font-size: 2.2rem;
      line-height: 1.8;
      width: 80%;
    }

    .versus {
      text-align: center;
      margin-top: 9rem;

      .row {
        align-items: center;

        .col {
          padding: 0 3rem;
        }
      }

      strong {
        display: block;
        font-size: 4rem;
        line-height: 1.7;
        font-weight: 700;
        margin-bottom: 2rem;

        span {
          font-weight: 400;
        }

        em {
          font-weight: 400;
        }
      }

      p {
        font-weight: 700;
        font-size: 2.2rem;
        line-height: 1.7;
        margin: 0;
        width: 100%;
      }

      .col {
        &:nth-child(2) {
          p {
            font-weight: 600;
            font-size: 14rem;
            line-height: 1;
            margin: -4rem 0 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .content {
      h2 {
        font-size: 3rem;
      }
    }

    .solutions {
      .col {
        padding-bottom: 100%;
        margin-bottom: 3rem;

        .col {
          flex: 1 1 33.3333%;
        }
      }
    }

    .services {
      .row {
        .col {
          margin-bottom: 6rem;

          h3 {
            font-size: 2.5rem;
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .odors {
      h2 {
        font-size: 3rem;
      }

      .container-full .row .col {
        flex: 1;
      }
    }

    .industries .row .col h2 {
      font-size: 2.5rem;
    }

    .vapor {
      h2 {
        font-size: 3rem;
      }

      .versus {
        .row {
          .col {
            margin-bottom: 6rem;
          }
        }
      }
    }

    .industries {
      background-position: center;

      .background-image {
        width: 100%;
        left: 0;
      }

      .row {
        .col {
          flex: 1 1 100%;

          /* &:last-child {
            display: none;
          } */
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .odors .container-full {
      padding: 0 3rem;

      .row {
        margin: 0 -1.5rem;

        .col {
          margin: 0 1.5rem;
        }
      }
    }
  }
`

const Mitigation = ({ data }) => {
  const acf = { ...data.acfMitigation }

  const [animate, setAnimate] = useState(0)

  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      const isInViewPort = element => {
        var bounding = element.getBoundingClientRect()

        if (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <=
            (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)
        ) {
          setAnimate(1)
        } else {
          return false
        }
      }

      const odorsTitle = document.querySelector(".odors h3")
      window.addEventListener("scroll", () => isInViewPort(odorsTitle))

      return () => window.removeEventListener("scroll", isInViewPort)
    }
  })

  return (
    <MitigationStyles>
      <PageHeader data={acf.header} />

      <div className="content container">
        {acf.services.title ? <h2>{acf.services.title}</h2> : null}

        {acf.services.content ? (
          <div dangerouslySetInnerHTML={{ __html: acf.services.content }}></div>
        ) : null}

        {acf.services.button &&
        acf.services.button.title &&
        acf.services.button.link ? (
          <a
            href={acf.services.button.link}
            target="_blank"
            rel="noreferrer"
            className="btn"
          >
            {acf.services.button.title}
          </a>
        ) : null}
      </div>

      {acf.services.services && acf.services.services.length ? (
        <div className="services container container-full">
          <div className="row">
            {acf.services.services.map(service => {
              return (
                <div className="col">
                  {service.image &&
                  service.image.localFile &&
                  service.image.localFile.childImageSharp &&
                  service.image.localFile.childImageSharp.fluid ? (
                    <Img
                      fluid={service.image.localFile.childImageSharp.fluid}
                      alt={service.image.altText}
                    />
                  ) : null}

                  <div class="service-content">
                    {service.title ? <h3>{service.title}</h3> : null}

                    {service.content ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: service.content }}
                      ></div>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : null}

      <div className="content container">
        {acf.solutions.title ? <h2>{acf.solutions.title}</h2> : null}

        {acf.solutions.content ? (
          <div
            dangerouslySetInnerHTML={{ __html: acf.solutions.content }}
          ></div>
        ) : null}

        {acf.solutions.button &&
        acf.solutions.button.title &&
        acf.solutions.button.link ? (
          <Link to={acf.solutions.button.link} className="btn">
            {acf.solutions.button.title}
          </Link>
        ) : null}
      </div>

      {acf.solutions.toggles && acf.solutions.toggles.length ? (
        <div className="solutions container container-full">
          <Toggles data={acf.solutions.toggles} />
        </div>
      ) : null}

      <div className="odors">
        <div className="container">
          {acf.odor.title ? <h2>{acf.odor.title}</h2> : null}

          {acf.odor.content ? (
            <div dangerouslySetInnerHTML={{ __html: acf.odor.content }}></div>
          ) : null}

          {acf.odor.subTitle ? (
            <h3 className={`${animate ? "animate" : ""}`}>
              {acf.odor.subTitle}
            </h3>
          ) : null}
        </div>
        <div
          className={`${
            animate
              ? "container container-full animate"
              : "container container-full"
          }`}
        >
          <div className="row">
            <div className="col">
              {acf.odor.image &&
              acf.odor.image.localFile &&
              acf.odor.image.localFile.childImageSharp &&
              acf.odor.image.localFile.childImageSharp.fluid ? (
                <Img
                  fluid={acf.odor.image.localFile.childImageSharp.fluid}
                  alt={acf.odor.image.altText}
                />
              ) : null}
            </div>
            <div className="col">
              {acf.odor.image &&
              acf.odor.image.localFile &&
              acf.odor.image.localFile.childImageSharp &&
              acf.odor.image.localFile.childImageSharp.fluid ? (
                <Img
                  fluid={acf.odor.image.localFile.childImageSharp.fluid}
                  alt={acf.odor.image.altText}
                />
              ) : null}
            </div>
            <div className="col">
              {acf.odor.image &&
              acf.odor.image.localFile &&
              acf.odor.image.localFile.childImageSharp &&
              acf.odor.image.localFile.childImageSharp.fluid ? (
                <Img
                  fluid={acf.odor.image.localFile.childImageSharp.fluid}
                  alt={acf.odor.image.altText}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="industries container container-full">
        {acf.industries.background &&
        acf.industries.background.localFile &&
        acf.industries.background.localFile.childImageSharp &&
        acf.industries.background.localFile.childImageSharp.fluid ? (
          <div className="background-image">
            <Img
              fluid={acf.industries.background.localFile.childImageSharp.fluid}
              alt={acf.industries.background.altText}
            />
          </div>
        ) : null}

        <div className="row">
          <div className="col col-content">
            {acf.industries.title ? <h2>{acf.industries.title}</h2> : null}

            {acf.industries.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.industries.content }}
              ></div>
            ) : null}

            {acf.industries.button &&
            acf.industries.button.title &&
            acf.industries.button.link ? (
              <Link to={acf.industries.button.link} className="btn btn-light">
                {acf.industries.button.title}
              </Link>
            ) : null}
          </div>
          <div className="col">
            {acf.industries.image &&
            acf.industries.image.localFile &&
            acf.industries.image.localFile.childImageSharp &&
            acf.industries.image.localFile.childImageSharp.fluid ? (
              <Img
                fluid={acf.industries.image.localFile.childImageSharp.fluid}
                alt={acf.industries.image.altText}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className="vapor" id="vapor">
        {acf.vapor.background &&
        acf.vapor.background.localFile &&
        acf.vapor.background.localFile.childImageSharp &&
        acf.vapor.background.localFile.childImageSharp.fluid ? (
          <div className="background-image">
            <Img
              fluid={acf.vapor.background.localFile.childImageSharp.fluid}
              alt={acf.vapor.background.altText}
            />
          </div>
        ) : null}

        {acf.vapor.backgroundVideo && acf.vapor.backgroundVideo.guid ? (
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={acf.vapor.backgroundVideo.guid}
              width="100%"
              height="100%"
              muted
              playing
              loop
            />
          </div>
        ) : null}

        <div class="container container-full">
          {acf.vapor.title ? <h2>{acf.vapor.title}</h2> : null}

          {acf.vapor.content ? (
            <div dangerouslySetInnerHTML={{ __html: acf.vapor.content }}></div>
          ) : null}
        </div>

        {acf.vapor.versus && acf.vapor.versus.length ? (
          <div class="container versus">
            <div className="row">
              {acf.vapor.versus.map(col => {
                return (
                  <div className="col">
                    {col.title ? (
                      <strong
                        dangerouslySetInnerHTML={{ __html: col.title }}
                      ></strong>
                    ) : null}

                    {col.content ? (
                      <p dangerouslySetInnerHTML={{ __html: col.content }}></p>
                    ) : null}
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}
      </div>

      <div className="content container">
        {acf.resources.title ? <h2>{acf.resources.title}</h2> : null}

        {acf.resources.content ? (
          <div
            dangerouslySetInnerHTML={{ __html: acf.resources.content }}
          ></div>
        ) : null}

        {acf.resources.button &&
        acf.resources.button.title &&
        acf.resources.button.link ? (
          <Link to={acf.resources.button.link} className="btn">
            {acf.resources.button.title}
          </Link>
        ) : null}
      </div>

      <Resources data={acf.resources.categories} />
    </MitigationStyles>
  )
}

export default Mitigation
