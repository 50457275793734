import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { SRLWrapper } from "simple-react-lightbox"
import ReactPlayer from "react-player/lazy"
import Banner from "../components/banner"
import Toggles from "./toggles"
import { wrap } from "lodash"

const HomeStyles = styled.section`
  .video-wrap {
    background-color: var(--lgrey);
    overflow: hidden;
    height: 700px;
    padding: 0;
  }

  .player-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;

    .react-player {
      video {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        width: auto !important;
        height: auto !important;
        top: 50%;
        left: 50%;
        object-fit: contain;
        transform: translate(-50%, -50%);
      }
    }
  }

  .solutions {
    padding: 0 2rem;

    .solutions-content {
      max-width: 1080px;
      padding: 5% calc(10% - 2rem);
      margin: 0 auto;
    }

    h2 {
      text-align: center;
      font-weight: 600;
      font-size: 2vw;
      line-height: 1.4;
      margin-top: 0;
    }

    p {
      text-align: center;
      font-size: 2.2rem;
      color: var(--grey);
      line-height: 1.4;
      font-weight: 500;
      margin: 45px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .technology {
    padding: 0 10%;
    margin: 12rem auto;

    h2 {
      font-size: 2.2vw;
      line-height: 1.4;
      font-weight: 600;
      margin: 3rem 0;
    }

    p {
      font-size: 2.2rem;
      color: var(--grey);
      font-weight: 500;
      line-height: 1.9;
      margin-bottom: 3rem;
    }

    .btn {
      margin-top: 1rem;
    }

    .gallery {
      padding-left: 15%;
      display: flex;
      flex-wrap: wrap;

      a {
        flex: 1 1 calc(40% - 2rem);
        padding: 0 0 2rem 2rem;

        img {
          display: block;
          filter: grayscale(100%);
          transition: filter 0.3s ease;

          &:hover {
            filter: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .video-wrap {
      height: 460px;
    }

    .solutions {
      h2 {
        font-size: 2.5rem;
      }

      p {
        font-size: 2rem;
      }
    }

    .technology {
      h2 {
        font-size: 2.5rem;
      }

      .gallery {
        padding-left: 0;
        margin-top: 3rem;
        margin-left: -2rem;
      }
    }
  }
`

const Home = ({ data }) => {
  const acf = { ...data.acfHome }

  return (
    <HomeStyles>
      {acf.video && acf.video.guid ? (
        <div className="video-wrap">
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={acf.video.guid}
              width="100%"
              height="100%"
              muted
              playing
              loop
            />
          </div>
        </div>
      ) : null}

      <div className="solutions container container-full">
        <div className="solutions-content">
          {acf.solutions.title ? <h2>{acf.solutions.title}</h2> : null}

          {acf.solutions.content ? (
            <div
              dangerouslySetInnerHTML={{ __html: acf.solutions.content }}
            ></div>
          ) : null}
        </div>

        <Toggles data={acf.toggles} />
      </div>
      <div className="technology container container-full">
        <div className="row">
          <div className="col">
            {acf.technology.subTitle ? (
              <div className="sub-title">{acf.technology.subTitle}</div>
            ) : null}

            {acf.technology.title ? <h2>{acf.technology.title}</h2> : null}

            {acf.technology.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.technology.content }}
              ></div>
            ) : null}

            {acf.technology.button &&
            acf.technology.button.title &&
            acf.technology.button.link ? (
              <Link to={acf.technology.button.link} className="btn">
                {acf.technology.button.title}
              </Link>
            ) : null}
          </div>
          <div className="col">
            {acf.technology.gallery.length ? (
              <SRLWrapper>
                <div className="gallery">
                  {acf.technology.gallery.map(image => {
                    return (
                      <React.Fragment>
                        {image &&
                        image.localFile &&
                        image.localFile.childImageSharp &&
                        image.localFile.childImageSharp.fluid ? (
                          <a href={image.sourceUrl}>
                            <Img
                              loading="eager"
                              fluid={image.localFile.childImageSharp.fluid}
                              alt={image.altText}
                            />
                          </a>
                        ) : null}
                      </React.Fragment>
                    )
                  })}
                </div>
              </SRLWrapper>
            ) : null}
          </div>
        </div>
      </div>

      <Banner data={acf.banner} />
    </HomeStyles>
  )
}

export default Home
