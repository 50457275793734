import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Sidebar from "./sidebar"

const PostStyles = styled.section`
  .single {
    margin-bottom: 9rem;

    .row {
      .col {
        box-sizing: border-box;
      }

      .main {
        flex: 1 1 calc(75% - 6rem - 1px);
        padding: 6rem 6rem 6rem 0;
        border-right: 1px solid var(--bd);

        .post {
          margin-bottom: 6rem;

          a {
            color: var(--body);

            &:hover {
              color: var(--blue);
            }
          }

          img {
            display: block;
          }

          /* .gatsby-image-wrapper {
            padding-top: 66.6666%;

            div[aria-hidden="true"] {
              padding: 0 !important;
            }
          } */

          h1 {
            font-weight: 400;
            font-size: 3rem;
            line-height: 5rem;
            margin: 0 0 1.5rem;
          }

          .meta {
            font-size: 1.8rem;
            line-height: 3.2rem;
            margin-bottom: 3rem;
          }

          p {
            font-size: 2.1rem;
            line-height: 3.5rem;
            margin: 3rem 0;
          }
        }
      }

      .sidebar {
        flex: 1 1 calc(25% - 3rem);
        padding: 6rem 0 6rem 3rem;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .single {
      .row {
        flex: 1 1 100%;

        .main {
          padding-right: 0;
          border-right: 0;

          .post {
            img {
              width: 100%;
            }
          }
        }

        .sidebar {
          padding-left: 0;
          flex: 1 1 100%;
        }
      }
    }
  }
`

const Post = ({ data }) => {
  return (
    <PostStyles>
      <div className="single container">
        <div className="row">
          <div className="col main">
            <div className="post">
              <h1>{data.title}</h1>
              <div className="meta">
                by Byers Scientific | {data.date}
                {data.categories && data.categories.nodes.length ? (
                  <React.Fragment>
                    {data.categories.nodes.map(cat => {
                      return (
                        <React.Fragment>
                          {" "}
                          | <Link to={cat.uri}>{cat.name}</Link>
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                ) : null}
              </div>

              {data.featuredImage &&
              data.featuredImage.node &&
              data.featuredImage.node.localFile &&
              data.featuredImage.node.localFile.childImageSharp &&
              data.featuredImage.node.localFile.childImageSharp.fluid ? (
                <Img
                  fluid={
                    data.featuredImage.node.localFile.childImageSharp.fluid
                  }
                  alt={data.featuredImage.node.altText}
                />
              ) : null}

              <div
                dangerouslySetInnerHTML={{
                  __html: data.content,
                }}
              ></div>
            </div>
          </div>
          <div className="col sidebar">
            <Sidebar />
          </div>
        </div>
      </div>
    </PostStyles>
  )
}

export default Post
