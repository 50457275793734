import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import PageHeader from "./page-header"
import Banner from "../components/banner"
import Resources from "../components/resources"

const MtStyles = styled.section`
  .page-header {
    text-align: right;

    h2 {
      width: 100%;
      margin-bottom: 0;
    }
  }

  .content {
    margin: 9rem auto;
    text-align: center;

    h2 {
      font-weight: 600;
      font-size: 3.6rem;
      line-height: 4.8rem;
      margin-bottom: 3rem;
    }

    p {
      font-weight: 500;
      font-size: 2.1rem;
      line-height: 3.2rem;
      margin: 3rem 0 4rem;
    }

    .btn {
      margin: 0;
    }
  }

  .resources-wrap {
    background-color: var(--tg);
    padding: 9rem 0;

    .content {
      margin-top: 0;
    }

    .resources {
      margin-bottom: 0;
    }
  }

  .services {
    padding: 0 6rem;
    margin-bottom: 12rem;

    .row {
      margin: 0 -3rem;

      .col {
        margin: 0 3rem;

        h3 {
          font-size: 2.4rem;
          line-height: 4.5rem;
          font-weight: 600;
        }

        p {
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
      }
    }
  }

  .top-banner {
    background-size: cover;
    background-position: center;
    padding-top: 20rem;
    padding-bottom: 20rem;
    text-align: right;
    color: #fff;

    .sub-title {
      color: #fff;
    }

    h1 {
      font-size: 3rem;
      font-weight: 700;
      line-height: 4.5rem;
      margin-bottom: 0;
    }
  }

  .about {
    background-size: cover;
    background-position: center;
    background-color: #0a0a0a;
    color: #fff;
    padding-top: 12rem;
    padding-bottom: 12rem;

    .row {
      align-items: center;
    }

    h2 {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 4.5rem;
      margin-top: 0;
    }

    ul {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2.1rem;
      margin: 3rem 0;
    }

    .btn {
      margin-top: 1rem;
    }

    .gatsby-image-wrapper {
      width: 50% !important;
      margin: 0 auto;
      /* padding: 0;
      display: block;

      img {
        transition: none !important;
      }

      picture {
        img {
          position: relative !important;
        }
      } */
    }
  }

  .banner {
    /* padding-top: 10%; */
    padding-bottom: 0;

    .btn {
      margin-bottom: 6rem;
    }

    .row {
      /* align-items: center; */

      h2 {
        margin-top: 0;
      }

      /* .btn {
        margin-bottom: 0;
      } */

      .col {
        &:last-child {
          display: flex;
          align-items: flex-end;
          .gatsby-image-wrapper {
            /* padding: 0; */
            width: 44% !important;
            margin: 0 auto;
            /* display: block;

          img {
            transition: none !important;
          }

          picture {
            img {
              position: relative !important;
            }
          } */
          }
        }
      }
    }
  }

  .benefits {
    margin: 12rem auto;

    .row {
      margin: 0 -5rem;

      .col {
        margin: 0 5rem;

        h2 {
          font-weight: 600;
          font-size: 3.6rem;
          line-height: 4.8rem;
          margin: 0 0 3rem;
        }

        p {
          font-weight: 500;
          font-size: 2.1rem;
          line-height: 3.2rem;
          margin: 3rem 0;
        }

        .btn {
          margin-top: 1rem;
        }

        img {
          display: block;
        }

        &:last-child {
          p {
            position: relative;
            z-index: 2;
            margin: -5rem 0 0 3rem;
            padding: 9rem 0 5rem 3rem;
            border-left: 2px solid var(--blue);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .services {
      .row {
        .col {
          margin-bottom: 6rem;

          img {
            width: 100%;
          }
        }
      }
    }

    .benefits {
      .row {
        .col {
          &:last-child {
            margin-top: 6rem;
          }
        }
      }
    }
  }
`

const Mt = ({ data }) => {
  const acf = { ...data.acfMt }

  return (
    <MtStyles>
      <PageHeader data={acf.header} />

      <div className="about container container-full">
        <div className="row">
          <div className="col">
            {acf.about.title ? <h2>{acf.about.title}</h2> : null}

            {acf.about.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.about.content }}
              ></div>
            ) : null}

            {acf.about.button &&
            acf.about.button.title &&
            acf.about.button.link ? (
              <Link to={acf.about.button.link} className="btn btn-light">
                {acf.about.button.title}
              </Link>
            ) : null}
          </div>
          <div className="col">
            {acf.about.image &&
            acf.about.image.localFile &&
            acf.about.image.localFile.childImageSharp &&
            acf.about.image.localFile.childImageSharp.fluid ? (
              <Img
                fluid={acf.about.image.localFile.childImageSharp.fluid}
                alt={acf.about.image.altText}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className="benefits container container-full">
        <div className="row">
          <div className="col">
            {acf.benefits.title ? <h2>{acf.benefits.title}</h2> : null}

            {acf.benefits.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.benefits.content }}
              ></div>
            ) : null}

            {acf.benefits.button &&
            acf.benefits.button.title &&
            acf.benefits.button.link ? (
              <Link to={acf.benefits.button.link} className="btn">
                {acf.benefits.button.title}
              </Link>
            ) : null}
          </div>
          <div className="col">
            {acf.benefits.image &&
            acf.benefits.image.localFile &&
            acf.benefits.image.localFile.childImageSharp &&
            acf.benefits.image.localFile.childImageSharp.fluid ? (
              <Img
                fluid={acf.benefits.image.localFile.childImageSharp.fluid}
                alt={acf.benefits.image.altText}
              />
            ) : null}

            {acf.benefits.caption ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.benefits.caption }}
              ></div>
            ) : null}
          </div>
        </div>
      </div>

      <Banner data={acf.banner} />

      <div className="resources-wrap">
        <div className="content container">
          {acf.resources.title ? <h2>{acf.resources.title}</h2> : null}

          {acf.resources.content ? (
            <div
              dangerouslySetInnerHTML={{ __html: acf.resources.content }}
            ></div>
          ) : null}

          {acf.resources.button &&
          acf.resources.button.title &&
          acf.resources.button.link ? (
            <Link to={acf.resources.button.link} className="btn">
              {acf.resources.button.title}
            </Link>
          ) : null}
        </div>

        <Resources data={acf.resources.categories} />
      </div>

      <div className="content container">
        {acf.solutions.title ? <h2>{acf.solutions.title}</h2> : null}

        {acf.solutions.content ? (
          <div
            dangerouslySetInnerHTML={{ __html: acf.solutions.content }}
          ></div>
        ) : null}

        {acf.solutions.button &&
        acf.solutions.button.title &&
        acf.solutions.button.link ? (
          <Link to={acf.solutions.button.link} className="btn">
            {acf.solutions.button.title}
          </Link>
        ) : null}
      </div>

      {acf.solutions &&
      acf.solutions.solutions &&
      acf.solutions.solutions.length ? (
        <div className="services container container-full">
          <div className="row">
            {acf.solutions.solutions.map(col => {
              return (
                <div className="col">
                  {col.image &&
                  col.image.localFile &&
                  col.image.localFile.childImageSharp &&
                  col.image.localFile.childImageSharp.fluid ? (
                    <Img
                      fluid={col.image.localFile.childImageSharp.fluid}
                      alt={col.image.altText}
                    />
                  ) : null}

                  {col.title ? <h2>{col.title}</h2> : null}

                  {col.content ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: col.content }}
                    ></div>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
      ) : null}
    </MtStyles>
  )
}

export default Mt
