import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { BiPlus } from "react-icons/bi"

const TogglesStyles = styled.section`
  .col {
    background-color: var(--tg);
    padding: 5rem 3rem 33%;
    margin: 0 2rem;
    position: relative;

    .sub-title {
      margin-bottom: 3rem;
    }

    h3 {
      font-weight: 600;
      font-size: 1.8vw;
      letter-spacing: 1px;
      line-height: 1.4;
      margin: 4rem 0;
    }

    p {
      font-size: 2.2rem !important;
      margin: 0 0 3rem !important;
      text-align: left !important;
    }

    .row {
      margin: 0 -1rem 3rem;

      .col {
        margin: 0;
        padding: 0;

        .btn {
          width: calc(100% - 2rem);
          text-align: center;
          margin: 0 1rem;
          border: 2px solid var(--lgrey);
          color: var(--grey)
          border-radius: 6px;
          /* font-size: 1rem;
          font-weight: 600;
          font-size: 1.1rem;*/
          padding: 16px 5px;
          background: none;

          svg {
            margin: 0 0 -3px 3px;
            color: var(--lgrey);
          }

          &:hover,
          &.active {
            background-color: var(--dblue);
            border-color: var(--dblue);
            color: #fff;

            svg {
              color: #fff;
            }
          }
        }
      }
    }

    .toggle {
      transition: max-height 0.3s ease;

      &.hide {
        max-height: 0;
        overflow: hidden;
      }

      ul {
        font-size: 1.8rem;
        font-weight: 500;
        padding: 3rem 0 0 2rem;
        margin: 0;
        line-height: 3rem;
      }
    }

    .featured-image {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      img {
        display: block;
        width: 100%;
        max-width: none;
      }
    }
  }

  @media screen and (max-width: 1680px) {
    .col {
      .row {
        .col {
          .btn {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .col {
      padding-bottom: 100%;
      margin-bottom: 3rem;

      h3 {
        font-size: 2.8rem;
      }

      .row {
        .col {
          flex: 1 1 33.3333%;

          .btn {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
`

const Toggles = ({ data }) => {
  let toggleArray = []

  data.map((toggle, toggleIndex) => {
    toggleArray[toggleIndex] = []

    toggle.buttons.map((button, buttonIndex) => {
      if (button.toggle) {
        toggleArray[toggleIndex][buttonIndex] = 0
      }
    })
  })

  const [toggles, setToggles] = useState(toggleArray)

  const toggleItem = (toggleIndex, buttonIndex) => {
    const toggle = document.querySelector(
      ".toggle-" + toggleIndex + "-" + buttonIndex
    )
    const allToggles = document.querySelectorAll(".toggle")
    let toggleItems = [...toggleArray]

    toggleItems[toggleIndex][buttonIndex] = 1
    setToggles(toggleItems)

    allToggles.forEach(toggle => {
      toggle.style.maxHeight = null
    })

    if (toggle.classList.contains("show")) {
      toggleItems[toggleIndex][buttonIndex] = 0
      setToggles(toggleItems)
      toggle.style.maxHeight = null
    } else {
      toggle.style.maxHeight = toggle.scrollHeight + "px"
    }
  }

  return (
    <TogglesStyles>
      <div className="row">
        {data.length &&
          data.map((toggle, toggleIndex) => {
            return (
              <div className="col">
                {toggle.subTitle ? (
                  <div className="sub-title">{toggle.subTitle}</div>
                ) : null}

                {toggle.title ? <h3>{toggle.title}</h3> : null}

                {toggle.content ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: toggle.content }}
                  ></div>
                ) : null}

                <div className="row">
                  {toggle.buttons.length &&
                    toggle.buttons.map((button, buttonIndex) => {
                      return (
                        <div className="col">
                          {button.toggle && button.title ? (
                            <button
                              className={`btn ${
                                toggles[toggleIndex][buttonIndex]
                                  ? `active`
                                  : ``
                              }`}
                              aria-label="Toggle"
                              onClick={() =>
                                toggleItem(toggleIndex, buttonIndex)
                              }
                            >
                              {button.title} <BiPlus />
                            </button>
                          ) : null}

                          {!button.toggle && button.link && button.title ? (
                            <React.Fragment>
                              {button.link.includes("#") ? (
                                <a href={button.link} className="btn">
                                  {button.title}
                                </a>
                              ) : (
                                <Link to={button.link} className="btn">
                                  {button.title}
                                </Link>
                              )}
                            </React.Fragment>
                          ) : null}
                        </div>
                      )
                    })}
                </div>

                {toggle.buttons.length &&
                  toggle.buttons.map((button, buttonIndex) => {
                    return (
                      <React.Fragment>
                        {button.toggleContent ? (
                          <div
                            className={`toggle toggle-${toggleIndex}-${buttonIndex} ${
                              toggles[toggleIndex][buttonIndex]
                                ? `show`
                                : `hide`
                            }`}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: button.toggleContent,
                              }}
                            ></div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    )
                  })}

                {toggle.image &&
                toggle.image.localFile &&
                toggle.image.localFile.childImageSharp &&
                toggle.image.localFile.childImageSharp.fluid ? (
                  <div className="featured-image">
                    <Img
                      fluid={toggle.image.localFile.childImageSharp.fluid}
                      alt={toggle.image.altText}
                    />
                  </div>
                ) : null}
              </div>
            )
          })}
      </div>
    </TogglesStyles>
  )
}

export default Toggles
