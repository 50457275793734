import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { FaChevronUp, FaChevronDown } from "react-icons/fa"
import styled from "styled-components"
import PageHeader from "./page-header"
import Resources from "./resources"
import Banner from "../components/banner"
import Toggle from "./toggle"

const EmissionsStyles = styled.section`
  .content {
    margin: 9rem auto;
    text-align: center;

    h2 {
      font-weight: 700;
      font-size: 2.2vw;
      line-height: 1.2;
      margin-bottom: 3rem;
    }

    p {
      font-weight: 500;
      font-size: 2.1rem;
      line-height: 1.8;
      margin: 3rem 0 4rem;
    }

    .btn {
      margin: 0;
    }

    &.text-left {
      text-align: left;
    }
  }

  .services {
    margin: 6rem auto;
    padding-left: 0;

    .row {
      align-items: center;

      .col {
        &:first-child {
          margin-right: 12rem;
        }

        img {
          display: block;
        }

        h2 {
          font-weight: 700;
          font-size: 2vw;
          line-height: 1.2;
          margin-bottom: 3rem;
        }

        p {
          font-weight: 500;
          font-size: 2rem;
          line-height: 1.8;
        }
      }
    }
  }

  .toggles {
    padding: 0;

    .row {
      .col {
        background-color: var(--heading);
        color: #fff;
        padding: 12rem 9rem;

        &:last-child {
          background-color: var(--body);
        }

        h2 {
          font-size: 2.6rem;
          line-height: 1.7;
          font-weight: 600;
          margin-top: 0;
        }

        p {
          font-weight: 500;
          font-size: 2rem;
          line-height: 1.8;
        }

        .toggle {
          button {
            font-size: 2rem;
            font-weight: 700;
          }

          p {
            font-size: 1.8rem;
            line-height: 1.7;
          }
        }
      }
    }
  }

  .doctors {
    padding: 0 10%;
    margin: 9rem auto;

    .row {
      /* align-items: stretch; */

      .col {
        /* flex-grow: 1;
        display: flex;
        flex-direction: column; */

        /* img {
          flex-grow: 1;
          display: flex;
          width: 100%;
        } */

        .gatsby-image-wrapper {
          /* padding: 0;
          overflow: initial !important;

          picture {
            img {
              position: relative !important;
            }
          } */
          /* div[aria-hidden="true"] {
            padding-bottom: 100% !important;
          } */
        }

        .doctor {
          background-color: var(--body);
          color: #fff;
          padding: 7.5rem;
          position: relative;
          /* height: 100%; */
          /* display: flex;
          flex-direction: column;
          justify-content: center; */

          .arrow {
            position: absolute;
            top: 2rem;
            left: 0;
            width: 100%;
            text-align: center;
          }

          svg {
            width: 30px;
            height: 30px;
          }

          h2 {
            margin-top: 0;
            font-weight: 600;
            font-size: 2.4rem;
            line-height: 4.5rem;
          }

          .position {
            margin: 0;
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 2.5rem;
          }

          p {
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.8rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &:last-child {
          .doctor {
            background-color: var(--dblue);
          }

          .arrow {
            top: auto;
            bottom: 2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .content {
      h2 {
        font-size: 3rem;
      }
    }

    .services {
      padding: 0 10%;

      img {
        width: 100%;
      }

      .sub-title {
        margin-top: 3rem;
      }

      h2 {
        font-size: 2.5rem !important;
      }

      .row {
        .col:first-child {
          margin: 0;
        }
      }
    }
  }
`

const Emissions = ({ data }) => {
  const acf = { ...data.acfEmissions }

  return (
    <EmissionsStyles>
      <PageHeader data={acf.header} />

      <div className="services container container-full">
        <div className="row">
          <div className="col">
            {acf.services.image &&
            acf.services.image.localFile &&
            acf.services.image.localFile.childImageSharp &&
            acf.services.image.localFile.childImageSharp.fluid ? (
              <Img
                fluid={acf.services.image.localFile.childImageSharp.fluid}
                alt={acf.services.image.altText}
              />
            ) : null}
          </div>
          <div className="col">
            {acf.services.subTitle ? (
              <div className="sub-title">{acf.services.subTitle}</div>
            ) : null}

            {acf.services.title ? <h2>{acf.services.title}</h2> : null}

            {acf.services.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: acf.services.content }}
              ></div>
            ) : null}

            {acf.services.button &&
            acf.services.button.title &&
            acf.services.button.link ? (
              <a
                href={acf.services.button.link}
                target="_blank"
                rel="noreferrer"
                className="btn"
              >
                {acf.services.button.title}
              </a>
            ) : null}
          </div>
        </div>
      </div>

      {acf.toggles && acf.toggles.length ? (
        <div className="toggles container container-full">
          <div className="row">
            {acf.toggles.map(toggle => {
              return (
                <div className="col">
                  {toggle.title ? <h2>{toggle.title}</h2> : null}

                  {toggle.content ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: toggle.content }}
                    ></div>
                  ) : null}

                  <Toggle data={toggle} />
                </div>
              )
            })}
          </div>
        </div>
      ) : null}

      <div className="content container">
        {acf.content.title ? <h2>{acf.content.title}</h2> : null}

        {acf.content.content ? (
          <div dangerouslySetInnerHTML={{ __html: acf.content.content }}></div>
        ) : null}

        {acf.content.button &&
        acf.content.button.title &&
        acf.content.button.link ? (
          <Link to={acf.content.button.link} className="btn">
            {acf.content.button.title}
          </Link>
        ) : null}
      </div>

      <Banner data={acf.banner} />

      <div className="doctors container container-full">
        <div className="row">
          <div className="col">
            {acf.doctors.leftColumn.image &&
            acf.doctors.leftColumn.image.localFile &&
            acf.doctors.leftColumn.image.localFile.childImageSharp &&
            acf.doctors.leftColumn.image.localFile.childImageSharp.fluid ? (
              <Img
                fluid={
                  acf.doctors.leftColumn.image.localFile.childImageSharp.fluid
                }
                alt={acf.doctors.leftColumn.image.altText}
              />
            ) : null}

            <div className="doctor">
              <div className="arrow">
                <FaChevronUp />
              </div>
              {acf.doctors.leftColumn.title ? (
                <h2>{acf.doctors.leftColumn.title}</h2>
              ) : null}

              {acf.doctors.leftColumn.position ? (
                <div className="position">
                  {acf.doctors.leftColumn.position}
                </div>
              ) : null}

              {acf.doctors.leftColumn.content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: acf.doctors.leftColumn.content,
                  }}
                ></div>
              ) : null}
            </div>
          </div>
          <div className="col">
            <div className="doctor">
              {acf.doctors.rightColumn.title ? (
                <h2>{acf.doctors.rightColumn.title}</h2>
              ) : null}

              {acf.doctors.rightColumn.position ? (
                <div className="position">
                  {acf.doctors.rightColumn.position}
                </div>
              ) : null}

              {acf.doctors.rightColumn.content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: acf.doctors.rightColumn.content,
                  }}
                ></div>
              ) : null}
              <div class="arrow">
                <FaChevronDown />
              </div>
            </div>

            {acf.doctors.rightColumn.image &&
            acf.doctors.rightColumn.image.localFile &&
            acf.doctors.rightColumn.image.localFile.childImageSharp &&
            acf.doctors.rightColumn.image.localFile.childImageSharp.fluid ? (
              <Img
                fluid={
                  acf.doctors.rightColumn.image.localFile.childImageSharp.fluid
                }
                alt={acf.doctors.rightColumn.image.altText}
              />
            ) : null}
          </div>
        </div>
      </div>

      <div className="content container text-left">
        {acf.resources.title ? <h2>{acf.resources.title}</h2> : null}

        {acf.resources.resources ? (
          <div
            dangerouslySetInnerHTML={{ __html: acf.resources.resources }}
          ></div>
        ) : null}

        {acf.resources.button &&
        acf.resources.button.title &&
        acf.resources.button.link ? (
          <Link to={acf.resources.button.link} className="btn">
            {acf.resources.button.title}
          </Link>
        ) : null}
      </div>

      <Resources data={acf.resources.categories} />
    </EmissionsStyles>
  )
}

export default Emissions
